import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseImage from "../components/BaseImage"
import MotionBackground from "../components/MotionBackground"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdB1TfiIezO9({ items, backgroundText, backgroundColor }) {
  const theme = useTheme()
  const ref = useRef(null)
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    overflow: hidden;
    color: ${theme.colors.global.light};

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    position: relative;
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedPartial}

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/12;
        grid-row: 1;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 4/15;
        grid-row: 2;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/12;
        grid-row: 3;
      }
    }

    &:nth-of-type(4) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2/15;
        grid-row: 4;
      }
    }

    &:nth-of-type(5) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/11;
        grid-row: 5;
      }
    }
  `

  css.media = css`
    position: relative;
    z-index: 1;
  `

  css.image = css`
    vertical-align: middle;
  `

  css.backgroundText = css`
    position: absolute;
    right: 0;
    left: 0;

    &:nth-of-type(1) {
      top: 0;
      transform: translateY(-25%);
    }

    &:nth-of-type(2) {
      bottom: 0;
      transform: translateY(-75%);
    }
  `

  return (
    <MotionBackground trigger={ref} 
    color={
      backgroundColor
        ? theme.colors[themeCurrent][backgroundColor]
        : theme.colors[themeCurrent].one
    }>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          <div>
            {backgroundText.map((backgroundTextItem, index) => (
              <GraphicBackgroundText
                key={index}
                backgroundText={backgroundTextItem}
                trigger={ref}
                css={css.backgroundText}
              />
            ))}
          </div>
          <div css={css.grid}>
            {items.map((item, index) => (
              <div key={index} css={css.gridItem}>
                <div css={css.media}>
                  <BaseImage src={item.image.src} css={css.image} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}

export const query = graphql`
  fragment CaseStudyIdB1TfiIezO9 on DataJsonComponentsProps {
    CaseStudyIdB1TfiIezO9 {
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      backgroundText
      backgroundColor
    }
  }
`
