import React, { useRef, useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const MotionSlide = ({ children, trigger, out, start, end, matchMedia }) => {
  const theme = useTheme()
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current
    const slotChildren = element.querySelectorAll(":scope > *")

    ScrollTrigger.saveStyles(slotChildren)
    const mediaQueries = {}

    mediaQueries[matchMedia] = () => {
      const timeline = gsap.timeline({
        paused: true,
        scrollTrigger: {
          animation: timeline,
          trigger: trigger ? trigger.current : ref.current,
          start: start ? start : theme.motion.scrollTrigger.start,
          end: end,
          scrub: true,
        },
      })
      const toPercent = out ? 100 : 0

      slotChildren.forEach((slotChild, index) => {
        timeline.fromTo(
          slotChild,
          {
            xPercent:
              slotChildren.length > 1 ? (index % 2 === 0 ? -100 : 100) : 100,
          },
          {
            xPercent:
              slotChildren.length > 1
                ? index % 2 === 0
                  ? toPercent
                  : -toPercent
                : -toPercent,
            duration: 0.5,
          },
          0
        )
      })
    }

    ScrollTrigger.matchMedia(mediaQueries)
  }, [])

  return <div ref={ref}>{children}</div>
}

MotionSlide.defaultProps = {
  out: false,
  start: null,
  end: false,
  matchMedia: "all",
}

export default MotionSlide
