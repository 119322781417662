import React from "react"
import { css, useTheme } from "@emotion/react"

import BaseImage from "../components/BaseImage"

const BaseImages = props => {
  const { src, srcSet, ...other } = props
  const theme = useTheme()
  const defaultImageCss = srcSet.map((media, mediaIndex) => {
    return css`
      display: block;

      @media (min-width: ${theme.breakpoints[media.media]}) {
        display: none;
      }
    `
  })

  return (
    <>
      <BaseImage src={src} {...other} css={defaultImageCss} />
      {srcSet.map((image, imageIndex) => {
        const imageCss = srcSet.map((media, mediaIndex) => {
          return css`
            display: none;

            @media (min-width: ${theme.breakpoints[media.media]}) {
              display: ${mediaIndex === imageIndex ? "block" : "none"};
            }
          `
        })

        return (
          <BaseImage
            key={imageIndex}
            src={image.src}
            {...other}
            css={imageCss}
          />
        )
      })}
    </>
  )
}

export default BaseImages
