import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import MotionFade from "../components/MotionFade"
import MotionSlide from "../components/MotionSlide"

export default function CaseStudyIdRFePoPyqH5({
  subheading,
  heading,
  text,
  items,
}) {
  const ref = useRef(null)
  const theme = useTheme()

  css.section = css`
    overflow: hidden;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "310px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  `

  css.gridItem = css`
    grid-column: 1;
  `

  css.gridItem1 = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 6 / span 6;
    }
  `

  css.gridItem2 = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 12 / span 12;
    }
  `

  css.itemsGrid = css`
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  `

  css.itemsGridItem = css`
    grid-column: 1;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 3 / span 3;
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.itemSubheading = css`
    position: relative;
    font-size: 16px;
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.typeAndTixel.one};

    &:after {
      content: "";
      position: relative;
      left: 50%;
      display: block;
      width: 200vw;
      height: 1px;
      margin: 20px 0 50px;
      background-color: #d2d4d6;
      transform: translateX(-50%);
    }
  `

  css.itemHeading = css`
    font-size: 60px;
    font-weight: ${theme.fontWeight.semiBold};
  `

  css.itemText = css`
    color: ${theme.colors.global.one};
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={[css.gridItem, css.gridItem1]}>
            <MotionFade>
              <div
                css={css`
                  position: relative;
                `}
              >
                <div css={css.subheading}>{subheading}</div>
                <h2 css={css.heading}>{heading}</h2>
              </div>
              <div css={css.text}>{text}</div>
            </MotionFade>
          </div>
        </div>
      </div>
      <MotionSlide
        trigger={ref}
        end="bottom center"
        matchMedia={`(min-width: ${theme.breakpoints.tablet})`}
      >
        <div
          css={css`
            overflow: hidden;
          `}
        >
          <div css={css.container}>
            <div css={css.grid}>
              <div css={[css.gridItem, css.gridItem2]}>
                <ul css={css.itemsGrid}>
                  {items.map((item, index) => (
                    <li key={index} css={css.itemsGridItem}>
                      <h3 css={css.itemSubheading}>{item.subheading}</h3>
                      <div css={css.itemHeading}>{item.heading}</div>
                      {item.text && <div css={css.itemText}>{item.text}</div>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </MotionSlide>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdRFePoPyqH5 on DataJsonComponentsProps {
    CaseStudyIdRFePoPyqH5 {
      subheading
      heading
      text
      items {
        subheading
        heading
        text
      }
    }
  }
`
