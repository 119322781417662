import React from "react"
import { css } from "@emotion/react"

const BaseImage = props => {
  const { src, ...other } = props

  css.video = css`
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-bottom: ${(9 / 16) * 100}%;
      height: 0;
    }
  `

  css.iframe = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  return (
    <div css={css.video}>
      <iframe
        css={css.iframe}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default BaseImage
