import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector, useDispatch } from "react-redux"
import { gsap, ScrollTrigger } from "gsap/all"

import { setCaseStudyCurrent } from "../store/transition-case-study"
import BaseFigureCaseStudy from "../components/BaseFigureCaseStudy"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function CaseStudyHeader({ heading, image, background }) {
  const themeGlobal = useTheme()
  const dispatch = useDispatch()
  const themeCurrent = useSelector(state => state.theme.current)
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current
    const figure = element.querySelectorAll(".figure")
    const timeline = gsap.timeline({ paused: true })

    timeline.to(figure, { yPercent: 33.333, rotate: 0.01, ease: "none" }, 0)

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: ref.current,
      start: "top top",
      scrub: 0,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [])

  css.section = css`
    overflow: hidden;
    position: relative;
    height: 100vh;
  `

  css.figure = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  `

  css.figureHeadings = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `

  css.figureHeadingsItem = css`
    ${themeGlobal.mixins.textOutline}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "200px",
        toSize: "400px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    opacity: 0.2;

    &:nth-of-type(2) {
      opacity: 0.5;
    }
  `

  css.figureMedia = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  css.figureMediaBackground = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: ${themeGlobal.colors[themeCurrent].one};
  `

  css.figureMediaImageBackground = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  `

  css.figureMediaImage = css`
    ${fluidRange(
      {
        prop: "width",
        fromSize: "200px",
        toSize: "500px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
  `

  return (
    <section ref={ref} css={css.section}>
      <BaseFigureCaseStudy
        className="figure"
        imageForeground={image ? image.src : null}
        imageBackground={background ? background.src : null}
        theme={themeCurrent}
        backgroundText={heading}
        backgroundTextShow={true}
        onStartLoad={(event) => {
          dispatch(setCaseStudyCurrent(null))
        }}
      />
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyHeader on DataJsonComponentsProps {
    CaseStudyHeader {
      heading
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      background {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    }
  }
`
