import React, { useState } from "react"
import { Form, Formik, FormikConsumer, useFormikContext } from "formik"
import * as Yup from "yup"
import "yup-phone-lite";

import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"
import axios from "axios"

import BaseSvg from "../components/BaseSvg"
import FormFieldInputNew from "../components/FormFieldInputNew"

const Debug = () => (
  <FormikConsumer>
    {({ validationSchema, validate, onSubmit, ...rest }) => (
      <pre
        style={{
          fontSize: ".85rem",
          padding: ".25rem .5rem",
          overflowX: "scroll",
        }}
      >
        {JSON.stringify(rest, null, 2)}
      </pre>
    )}
  </FormikConsumer>
)

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const Wizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  // Swap these if enabling multiple steps
  // const isLastStep = stepNumber === totalSteps - 1 - 1
  // const isConfirmationStep = stepNumber === totalSteps - 1
  const isLastStep = true
  const isConfirmationStep = false

  const next = values => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = values => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values, bag) => {
    if (isLastStep) {
      await onSubmit(values, bag)
        .then(response => {
          bag.setTouched({})
          bag.setStatus({ success: response.data.inlineMessage })
          window?.dataLayer?.push({
            'event': 'formSubmission',
            'status': 'success',
            'form': 'contact',
           })
          next(values)
        })
        .catch(error => {
          bag.setStatus({ error: error.message })
          window?.dataLayer?.push({
            'event': 'formSubmission',
            'status': 'error',
            'form': 'contact',
           })
        })
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {formik => (
        <Form noValidate>
          {step}
          <WizardNavigation
            stepNumber={stepNumber}
            isLastStep={isLastStep}
            isConfirmationStep={isConfirmationStep}
            previous={previous}
          />
          {formik.status && <WizardValidation status={formik.status} />}
          {/* <Debug /> */}
        </Form>
      )}
    </Formik>
  )
}

const WizardValidation = props => {
  const { status, ...other } = props
  const theme = useTheme()

  css.status = css`
    margin-top: 10px;
  `

  css.error = css`
    color: ${theme.colors.global.error};
  `

  return (
    <div css={css.status}>
      {status && status.error && (
        <div css={css.error} {...other}>
          {status.error}
        </div>
      )}
      {status && status.success && (
        <div css={css.success} {...other}>
          {status.success}
        </div>
      )}
    </div>
  )
}

const WizardStep = ({ children }) => children

const WizardNavigation = props => {
  const {
    stepNumber,
    isLastStep,
    isConfirmationStep,
    previous,
    ...other
  } = props
  const theme = useTheme()
  const formik = useFormikContext()

  css.navigation = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  `

  css.buttonPrevious = css`
    ${theme.mixins.button}
    position: relative;
    height: 28px;
    font-weight: ${theme.fontWeight.semiBold};
    text-transform: uppercase;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    color: ${rgba(theme.colors.global.light, 0.2)};

    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "14px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    &:hover {
      &:before {
        transform: scaleX(1);
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: block;
      height: 3px;
      margin-top: 6px;
      background-color: ${rgba(theme.colors.global.light, 0.2)};
      transform: scaleX(0);
      transform-origin: left;
      transition-property: transform;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;
    }
  `

  css.buttonNext = css`
    ${theme.mixins.button}
    width: 125px;
    height: 50px;
    margin-left: auto;
    border-radius: 500px;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.global.light};
    background-color: ${theme.colors.typeAndTixel.one};

    &:disabled {
      background-color: ${rgba(theme.colors.typeAndTixel.one, 0.2)};
    }
  `

  return (
    <>
      {!isConfirmationStep && (
        <div css={css.navigation} {...other}>
          {stepNumber > 0 && (
            <button
              css={css.buttonPrevious}
              onClick={() => previous(formik.values)}
              type="button"
            >
              Back
            </button>
          )}
          <div>
            <button
              css={css.buttonNext}
              disabled={
                formik.isSubmitting || (formik.status && formik.status.success)
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const WizardStepHeading = props => {
  const {
    subheading,
    subheadingCaption,
    heading,
    headingCaption,
    ...other
  } = props
  const theme = useTheme()

  css.headings = css`
    margin-bottom: 50px;
  `

  css.subheading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "26px",
        toSize: "26px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.subheadingCaption = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "26px",
        toSize: "26px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    opacity: 0.5;
  `

  css.heading = css`
    margin: 80px 0 0 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "40px",
        toSize: "40px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
  `

  css.headingCaption = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "26px",
        toSize: "26px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    opacity: 0.5;
  `

  return (
    <div {...other} css={css.headings}>
      {subheading && <div css={css.subheading}>{subheading}</div>}
      {subheadingCaption && (
        <div css={css.subheadingCaption}>{subheadingCaption}</div>
      )}
      {heading && <h2 css={css.heading}>{heading}</h2>}
      {headingCaption && <div css={css.headingCaption}>{headingCaption}</div>}
    </div>
  )
}

const FormContact = () => {
  const hubspotFormsApi = process.env.GATSBY_API_HUBSPOT_FORM_CONTACT
  const hubspotRequest = values => {
    const hubspotProperties = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: values.contactFirstName,
        },
        {
          name: "lastname",
          value: values.contactLastName,
        },
        {
          name: "email",
          value: values.contactEmail,
        },
        {
          name: "phone",
          value: values.contactPhone,
        },
        {
          name: "company",
          value: values.contactCompany,
        },
        {
          name: "message",
          value: values.contactMessage,
        },
      ],
      context: {
        pageUri: window.location.origin,
        pageName: document.title,
      },
    }
    return axios.post(hubspotFormsApi, hubspotProperties)
  }

  return (
    <div>
      <Wizard
        initialValues={{
          contactFirstName: "",
          contactLastName: "",
          contactEmail: "",
          contactPhone: "",
          contactCompany: "",
          contactMessage: "",
        }}
        onSubmit={async values => {
          return hubspotRequest(values)
        }}
      >
        <WizardStep
          validationSchema={Yup.object({
            contactFirstName: Yup.string().required("Required"),
            contactLastName: Yup.string().required("Required"),
            contactEmail: Yup.string()
              .label("Email")
              .email()
              .required("Required"),
            contactPhone: Yup.string()
              .phone("AU", "Please enter a valid phone number")
              .required("Required"),
            contactCompany: Yup.string().required("Required"),
            contactMessage: Yup.string().required("Required"),
          })}
        >
          <FormFieldInputNew
            component="input"
            name="contactFirstName"
            label="First Name"
            type="text"
          />
          <FormFieldInputNew
            component="input"
            name="contactLastName"
            label="Last Name"
            type="text"
          />
          <FormFieldInputNew
            as="input"
            name="contactEmail"
            label="Email"
            type="email"
          />
          <FormFieldInputNew
            as="input"
            name="contactPhone"
            label="Phone Number"
            type="text"
          />
          <FormFieldInputNew
            as="input"
            name="contactCompany"
            label="Company"
            type="text"
          />
          <FormFieldInputNew
            as="textarea"
            name="contactMessage"
            label="Tell us about your project"
          />
        </WizardStep>
      </Wizard>
    </div>
  )
}

export default FormContact
