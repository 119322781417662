import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import { percentage } from "../utilities/functions"
import BaseImage from "../components/BaseImage"
import BaseVideo from "../components/BaseVideo"
import WrapperDevice from "../components/WrapperDevice"

export default function CaseStudyIdQ6bhEKaoDY({
  subheading,
  heading,
  text,
  items,
}) {
  const ref = useRef(null)
  const theme = useTheme()

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns:
      ${percentage(101, 331)}
      ${percentage(129, 331)}
      ${percentage(101, 331)};
    grid-row-gap: 50px;
    height: 100%;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: ${theme.grid.gutter};
    }
  `

  css.gridItem = css`
    position: relative;

    &:nth-of-type(1) {
      grid-column: 1 / 3;
      grid-row: 1;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 6;
        grid-row: 1;
        margin-top: 30%;
      }
    }

    &:nth-of-type(2) {
      grid-column: 2 / 4;
      grid-row: 2;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8 / 13;
        grid-row: 1;
      }
    }
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <WrapperDevice>
                {item.image && !item.video && !item.image.srcset && (
                  <BaseImage src={item.image.src} />
                )}
                {item.video && item.image && (
                  <BaseVideo src={item.video.src} image={item.image.src} />
                )}
              </WrapperDevice>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdQ6bhEKaoDY on DataJsonComponentsProps {
    CaseStudyIdQ6bhEKaoDY {
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
    }
  }
`
