import React from "react"
import { css } from "@emotion/react"

const WrapperAspectRatio = (props) => {
  const { children, ...other } = props

  css.container = css`
    position: relative;
  `

  css.content = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `

  return (
    <div css={css.container} {...other}>
      <div css={css.content}>{children}</div>
    </div>
  )
}

export default WrapperAspectRatio
