import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"

import BaseImage from "../components/BaseImage"
import MotionBackground from "../components/MotionBackground"

export default function PageAboutClients({ subheading, heading, items }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "73px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
    }
  `

  css.itemGrid = css`
    ${theme.mixins.grid.default}
    grid-template-columns: repeat(3, minmax(0, 1fr));
    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "42.5px",
        toSize: "85px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.itemGridItem = css`
    grid-column: span 1 / span 1;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 3 / span 3;
    }
  `

  css.subheading = css`
    margin-top: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "24px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    color: ${rgba(theme.colors.global.light, 1)};
  `

  css.heading = css`
    ${theme.mixins.typography.heading1Alt}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.global.light};
  `

  css.item = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity: 0.5;
  `

  css.itemHeading = css`
    margin: 0;
    font-size: 24px;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.itemList = css`
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
  `

  css.itemListItem = css`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 5px;
    margin-top: 10px;

    &:before {
      content: "-";
    }
  `

  return (
    <MotionBackground trigger={ref} color={theme.colors.typeAndTixel.one}>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          <div css={css.grid}>
            <div css={css.gridItem}>
              <h2 css={css.subheading}>{subheading}</h2>
              <div css={css.heading}>{heading}</div>
            </div>
            <div css={css.gridItem}>
              <div css={css.itemGrid}>
                {items.map((item, index) => (
                  <div key={index} css={css.itemGridItem}>
                    <div css={css.item}>
                      <BaseImage src={item.image.src} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}

export const query = graphql`
  fragment PageAboutClients on DataJsonComponentsProps {
    PageAboutClients {
      subheading
      heading
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
