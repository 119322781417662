import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import { useMousePosition } from "../utilities/hooks"
import { percentage, randomInteger, fluid } from "../utilities/functions"
import FormContact from "../components/FormContact"
import BaseSvg from "../components/BaseSvg"
import BaseImage from "../components/BaseImage"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function PageContactForm({
  heading,
  image,
  itemsContact,
  itemsSocial,
}) {
  const theme = useTheme()

  css.section = css`
    color: ${theme.colors.typeAndTixel.one};
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "200px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}

    grid-template-rows: auto 1fr;
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 9;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 9;
        grid-row: 2;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 10 / 15;
        grid-row: 1 / 3;
      }
    }
  `

  css.heading = css`
    margin: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "76px",
        toSize: "140px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
    white-space: pre-wrap;
    color: #a7aa00;
    mix-blend-mode: difference;

    position: relative;
    z-index: 1;
  `

  css.image = css`
    margin: 0;
  `

  css.subheading = css`
    font-size: 16px;
    font-weight: ${theme.fontWeight.normal};
    color: #1c2631;
    opacity: 0.5;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "40px",
        toSize: "60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "10px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.social = css`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 40px;
  `

  css.socialItemLink = css`
    text-decoration: none;
    color: inherit;
  `

  css.socialItemLinkIcon = css`
    width: 30px;
  `

  css.contact = css`
    list-style: none;
    margin: 0;
    padding: 0;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "-40px",
        toSize: "-60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    display: inline-grid;
  `

  css.contactItemLink = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "24px",
        toSize: "28px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.normal};
    text-decoration: none;
    color: inherit;
  `

  css.form = css`
    margin-top: 30px;
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <h1 css={css.heading} className="heading">
              {heading}
            </h1>
          </div>
          <div css={css.gridItem}>
            <PageContactFormImage css={css.image}>
              <BaseImage src={image.src} />
            </PageContactFormImage>
          </div>
          <div css={css.gridItem}>
            <ul css={css.contact} className="contact">
              {itemsContact.map((item, index) => {
                return (
                  <li key={index}>
                    <h2 css={css.subheading}>{item.heading}</h2>
                    <a css={css.contactItemLink} href={item.link}>
                      {item.text}
                    </a>
                  </li>
                )
              })}
            </ul>
            <h2 css={css.subheading}>Socials</h2>
            <ul css={css.social} className="social">
              {itemsSocial.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      css={css.socialItemLink}
                      href={item.link}
                      target="_blank"
                    >
                      <BaseSvg css={css.socialItemLinkIcon} src={item.icon} />
                    </a>
                  </li>
                )
              })}
            </ul>
            <h2 css={css.subheading}>Or fill out a form</h2>
            <div css={css.form}>
              <FormContact />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function PageContactFormImage(props) {
  const { children, ...other } = props
  const ref = useRef()
  const refImage = useRef()
  const mouse = useMousePosition()
  const distance = useRef(randomInteger(25, 100))
  const speed = useRef(randomInteger(0, 200))

  useEffect(() => {
    const element = ref.current
    const elementSpeed = speed.current
    const timeline = gsap.timeline({ paused: true })

    timeline.fromTo(
      element,
      {
        yPercent: elementSpeed / 2,
      },
      {
        yPercent: elementSpeed / -2,
        duration: 1,
      },
      0
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      scrub: true,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [])

  useEffect(() => {
    const element = refImage.current
    const elementDistance = distance.current
    let mouseX = mouse.x
    let mouseY = mouse.y
    const viewportCenterX = window.innerWidth / 2
    const viewportCenterY = window.innerHeight / 2

    window.addEventListener("mousemove", e => {
      mouseX = e.x
      mouseY = e.y
    })

    gsap.ticker.add(() => {
      const percentageFromViewportCenterX = mouseX / viewportCenterX
      const percentageFromViewportCenterY = mouseY / viewportCenterY

      gsap.to(element, {
        x: percentageFromViewportCenterX * -elementDistance,
        y: percentageFromViewportCenterY * -elementDistance,
        duration: 1,
        overwrite: true,
      })
    })
  }, [])

  return (
    <figure ref={ref} {...other}>
      <div ref={refImage}>{children}</div>
    </figure>
  )
}

export const query = graphql`
  fragment PageContactForm on DataJsonComponentsProps {
    PageContactForm {
      heading
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      itemsContact {
        heading
        text
        link
      }
      itemsSocial {
        icon
        text
        link
      }
    }
  }
`
