import { useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger, SplitText } from "gsap/all"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, SplitText)
}

const MotionSlide = ({ children, trigger, pin, headingEnter, markers }) => {
  const theme = useTheme()

  useEffect(() => {
    const elementPin = pin.current
    const elementHeadingEnter = headingEnter.current
    const elementHeading = elementHeadingEnter
    const splitEnter = new SplitText(elementHeadingEnter, {
      type: "words, lines",
    })
    const scrollTriggers = []
    const timeline = gsap.timeline({ paused: true })

    timeline.to(
      {},
      {
        duration: 2,
        ease: "none",
      }
    )

    timeline.fromTo(
      elementPin,
      { y: "0vh" },
      { y: "-100vh", duration: 0.01, ease: "none" }
    )

    timeline.fromTo(
      splitEnter.words,
      {
        clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
      },
      {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 1,
        ease: "none",
      }
    )

    timeline.to(
      {},
      {
        duration: 2,
        ease: "none",
      }
    )

    timeline.fromTo(
      splitEnter.words,
      {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      },
      {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        duration: 1,
        ease: "none",
      }
    )

    timeline.fromTo(
      elementPin,
      { y: "-100vh" },
      { y: "0vh", duration: 0.01, ease: "none" }
    )

    timeline.to(
      {},
      {
        duration: 2,
        ease: "none",
      }
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: trigger ? trigger.current : null,
      start: () => {
        return `top bottom`
      },
      pin: elementPin,
      pinSpacing: false,
      scrub: true,
      markers: markers,
    })

    scrollTriggers.push(scrollTrigger)

    return () => {
      scrollTriggers.forEach(scrollTrigger => scrollTrigger.kill())
    }
  }, [])

  return children
}

export default MotionSlide
