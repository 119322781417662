import React, { useRef } from "react"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import MotionSlide from "../components/MotionSlide"

const GraphicBackgroundText = props => {
  const { backgroundText, trigger, ...other } = props
  const theme = useTheme()
  const ref = useRef(null)

  css.backgroundText = css`
    ${theme.mixins.textOutline}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "80px",
        toSize: "200px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    opacity: 0.4;
  `
  return (
    <div ref={ref} css={css.backgroundText} {...other}>
      <MotionSlide trigger={ref} start="top bottom">
        {backgroundText.map((text, index) => (
          <div key={index}>{text}</div>
        ))}
      </MotionSlide>
    </div>
  )
}

export default GraphicBackgroundText
