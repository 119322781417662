import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseImage from "../components/BaseImage"
import BaseImages from "../components/BaseImages"
import BaseVideo from "../components/BaseVideo"
import BaseEmbed from "../components/BaseEmbed"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdApMr5MM5MR({ grid, items, backgroundText, inverse, topSpacing }) {
  const ref = useRef(null)
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    color: ${inverse ? theme.colors.global.light : "inherit"};
    ${topSpacing &&
      css`
        ${fluidRange(
          {
            prop: "paddingTop",
            fromSize: "100px",
            toSize: "150px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
      `
    }

  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
    position: relative;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${grid &&
    grid.rowGap &&
    css`
      ${fluidRange(
        {
          prop: "gridRowGap",
          fromSize: grid.rowGap.min,
          toSize: grid.rowGap.max,
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    `}

    ${grid &&
    grid.mobileColums &&
    css`
      grid-template-columns: repeat(${grid.mobileColums}, 1fr);
    `}
  `

  css.gridItem = css`
    grid-column: 1 / 1;
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.backgroundText = (offset) => css`
    position: absolute;
    right: 0;
    left: 0;
    top: ${offset};
    transform: translateY(-50%);
    -webkit-text-stroke-color: ${inverse ? theme.colors.global.light : theme.colors[themeCurrent].one};
  `

  CaseStudyIdApMr5MM5MR.defaultProps = {
    topSpacing: false,
  }
  

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        {backgroundText && backgroundText.map((item, index) => (
          <GraphicBackgroundText
            key={index}
            backgroundText={item.text}
            trigger={ref}
            css={css.backgroundText(item.offset)}
          />
        ))}
        <div css={css.grid}>
          {items.map((item, index) => {
            const gridItem = item.grid.map(media => {
              if (media.media === 'default') {
                return css`
                  grid-column: ${media.column};
                  ${media.row &&
                  css`
                    grid-row: ${media.row};
                  `}
                `
              }

              return css`
                @media (min-width: ${theme.breakpoints[media.media]}) {
                  grid-column: ${media.column};
                  ${media.row &&
                  css`
                    grid-row: ${media.row};
                  `}
                }
              `
            })

            return (
              <div key={index} css={[css.gridItem, gridItem]}>
                {item.Image && <BaseImage src={item.Image.image.src} />}
                {item.ImageAdaptive && (
                  <BaseImages
                    src={item.ImageAdaptive.image.src}
                    srcSet={item.ImageAdaptive.image.srcset}
                  />
                )}
                {item.Video && (
                  <BaseVideo
                    src={item.Video.video.src}
                    image={item.Video.image.src}
                  />
                )}
                {item.Embed && (
                  <BaseEmbed
                    src={item.Embed.embed.src}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Image on DataJsonComponentsPropsCaseStudyIdApMr5MM5MRItems {
    Image {
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  fragment ImageAdaptive on DataJsonComponentsPropsCaseStudyIdApMr5MM5MRItems {
    ImageAdaptive {
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        srcset {
          media
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  fragment Video on DataJsonComponentsPropsCaseStudyIdApMr5MM5MRItems {
    Video {
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      video {
        src {
          publicURL
        }
      }
    }
  }
  fragment Embed on DataJsonComponentsPropsCaseStudyIdApMr5MM5MRItems {
    Embed {
      embed {
        src
      }
    }
  }

  fragment CaseStudyIdApMr5MM5MR on DataJsonComponentsProps {
    CaseStudyIdApMr5MM5MR {
      inverse
      topSpacing
      grid {
        mobileColums
        rowGap {
          min
          max
        }
      }
      items {
        grid {
          media
          column
        }
        ...Image
        ...ImageAdaptive
        ...Video
        ...Embed
      }
      backgroundText {
        offset
        text
      }
    }
  }
`
