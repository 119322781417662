import React, { useRef, useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const MotionFade = ({ delay, children }) => {
  const theme = useTheme()
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current
    const elements = element.querySelectorAll(":scope > *")
    const timeline = gsap.timeline({ paused: true, delay: delay })

    timeline.from(
      elements,
      {
        autoAlpha: 0,
        duration: 1,
        stagger: { each: theme.motion.stagger.each },
      },
      0
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: ref.current,
      start: theme.motion.scrollTrigger.start,
      scrub: false,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [theme, delay])

  return <div ref={ref}>{children}</div>
}

export default MotionFade
