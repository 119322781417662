import React, { useEffect, useRef, useState } from "react"
import { css, useTheme } from "@emotion/react"
// import WaveSurfer from "WaveSurfer"
import { useSelector } from "react-redux"
import { fluidRange } from "polished"

import { setCaseStudyCurrent } from "../store/transition-case-study"
import BaseSvg from "../components/BaseSvg"

const BaseImage = props => {
  const { src, ...other } = props
  const refButton = useRef(null)
  const refAudio = useRef(null)
  const refSrc = useRef(null)
  const refWave = useRef(null)
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(null)
  const handlerClick = () => {
    refAudio.current.playPause()
  }
  const handlerPlay = () => {
    setIsPlaying(true)
  }
  const handlerPause = () => {
    setIsPlaying(false)
  }
  const handlerReady = () => {
    const time = new Date(refAudio.current.getDuration() * 1000)
      .toISOString()
      .substr(15, 4)

    setDuration(time)
  }

  css.audio = css`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 30px;
    justify-content: center;
    align-items: center;
    ${"" /* padding: 30px; */}
    ${fluidRange(
      {
        prop: "padding",
        fromSize: "15px",
        toSize: "30px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    border-radius: 20px;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "12px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    color: ${theme.colors[themeCurrent].one};
    background-color: ${theme.colors.global.light};
    box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.04);
    text-align: left;
  `

  css.wave = css`
    > * {
      ${fluidRange(
        {
          prop: "height",
          fromSize: "30px",
          toSize: "60px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    }
  `

  css.button = css`
    ${theme.mixins.button}
    ${fluidRange(
      {
        prop: "width",
        fromSize: "30px",
        toSize: "60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "height",
        fromSize: "30px",
        toSize: "60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    border-radius: 50%;
    color: ${theme.colors.global.light};
    background-color: ${theme.colors[themeCurrent].one};
  `

  css.buttonIcon = css`
    ${fluidRange(
      {
        prop: "width",
        fromSize: "10px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  useEffect(() => {
    const elementButton = refButton.current
    const elementAudio = refAudio.current
    const elementSrc = refSrc.current
    const elementWave = refWave.current
    let resizeObserver = null

    if (!elementAudio) {
      if (typeof window !== `undefined`) {
        const WaveSurfer = require("wavesurfer.js")

        refAudio.current = WaveSurfer.create({
          barWidth: 5,
          barRadius: 2.5,
          barGap: 5,
          cursorWidth: 1,
          container: elementWave,
          backend: "WebAudio",
          height: 30,
          progressColor: theme.colors[themeCurrent].one,
          responsive: true,
          waveColor: "#E7E7E7",
          cursorColor: "transparent",
        })

        refAudio.current.load(elementSrc)
        refAudio.current.on("ready", handlerReady)
        refAudio.current.on("play", handlerPlay)
        refAudio.current.on("pause", handlerPause)

        resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            const button = entry.target
            refAudio.current.setHeight(Math.floor(button.offsetHeight))
          }
        })

        resizeObserver.observe(elementButton)
      }
    }

    return () => {
      refAudio.current.un("ready", handlerReady)
      refAudio.current.un("play", handlerPlay)
      refAudio.current.un("pause", handlerPause)

      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <div css={css.audio} {...other}>
      <button
        ref={refButton}
        css={css.button}
        onClick={() => {
          handlerClick()
        }}
      >
        {!isPlaying && <BaseSvg css={css.buttonIcon} src="play" />}
        {isPlaying && <BaseSvg css={css.buttonIcon} src="pause" />}
      </button>
      <div ref={refWave} css={css.wave} />
      <div>{duration}</div>
      <audio id="audio" ref={refSrc} src={src.publicURL} />
    </div>
  )
}

export default BaseImage
