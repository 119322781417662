import React, { useRef, useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const WrapperSticky = ({ children, trigger }) => {
  const theme = useTheme()
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current

    const scrollTrigger = ScrollTrigger.create({
      trigger: trigger ? trigger.current : null,
      start: () => {
        return `top center-=${element.offsetHeight / 2}`
      },
      end: () => {
        return `bottom center+=${element.offsetHeight / 2}`
      },
      pin: element,
      pinSpacing: false,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [trigger, theme])

  return <div ref={ref}>{children}</div>
}

export default WrapperSticky
