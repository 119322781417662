import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import BaseSvg from "../components/BaseSvg"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function FrontPageFooter({ siteTitle }) {
  const theme = useTheme()
  const ref = useRef(null)
  const [counter, setCounter] = useState(0)

  css.footer = css`
    position: fixed;
    bottom: 0;
    ${"" /* z-index: 3; */}
    width: 100%;
    ${"" /* height: 0; */}
    color: #a7aa00;
    mix-blend-mode: difference;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "16px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.semiBold};
  `

  css.container = css`
    ${fluidRange(
      {
        prop: "padding",
        fromSize: "40px",
        toSize: "54px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.grid = css`
    display: flex;
    justify-content: space-between;
  `

  css.counter = css`
    color: inherit;
  `

  css.scroll = css`
    display: inline-flex;
    align-items: center;
  `

  css.scrollIcon = css`
    margin-left: 5px;
  `

  return (
    <FrontPageFooterMotion trigger={ref} onEnter={setCounter}>
      <footer ref={ref} css={css.footer}>
        <div css={css.container}>
          <div css={css.grid}>
            <div>
              <div>0{counter}</div>
            </div>
            <div>
              <div css={css.scroll}>
                Scroll
                <BaseSvg css={css.scrollIcon} src="arrow-down" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </FrontPageFooterMotion>
  )
}

FrontPageFooter.propTypes = {
  siteTitle: PropTypes.string,
}

FrontPageFooter.defaultProps = {
  siteTitle: ``,
}

function FrontPageFooterMotion({ children, trigger, onEnter }) {
  const theme = useTheme()

  useEffect(() => {
    const element = trigger.current
    const panels = document.querySelectorAll(".panel")
    const scrollTriggers = []

    panels.forEach((panel, index) => {
      const scrollTrigger = ScrollTrigger.create({
        trigger: panel,
        end: "+=0",
        onEnter: () => {
          onEnter(index)
        },
        onEnterBack: () => {
          onEnter(index - 1)
        },
      })

      scrollTriggers.push(scrollTrigger)
    })

    return () => {
      scrollTriggers.forEach(scrollTrigger => scrollTrigger.kill())
    }
  }, [theme, trigger])

  return <>{children}</>
}
