import React, { useRef, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseVideo from "../components/BaseVideo"
import WrapperDevice from "../components/WrapperDevice"
import MotionFade from "../components/MotionFade"
import MotionParallax from "../components/MotionParallax"
import MotionBackground from "../components/MotionBackground"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdYHaQNzfkUh({
  inverse,
  backgroundColor,
  subheading,
  heading,
  text,
  items,
  backgroundText,
  disableLeave,
}) {
  const ref = useRef(null)
  const refs = useRef(items.map(() => createRef(null)))
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    position: relative;
    color: ${inverse ? theme.colors.global.light : "inherit"};

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "-42px",
        toSize: "-72px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
      ${
      "" /* &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: ${theme.colors[themeCurrent].three};

      ${fluidRange(
        {
          prop: "bottom",
          fromSize: "60px",
          toSize: "50px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    } */
    };
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.containerAlt = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      ${theme.mixins.container.xl}
    }

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 50px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/6;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 7/13;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/13;
        margin: 0 ${(130 / theme.grid.container.replace("px", "")) * -100}%;
      }
    }
  `

  css.imageGrid = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${
      "" /* grid-template-rows: ${(88 / 900) * 100}% max-content ${(88 / 900) * 100}% max-content ${(88 / 900) * 100}%; */
    }
    grid-row-gap: 22px;

    ${fluidRange(
      {
        prop: "gridColumnGap",
        fromSize: "34px",
        toSize: "66px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: none;
      margin: 0 ${(130 / theme.grid.container.replace("px", "")) * -100}%;
    }
  `

  css.imageGridItem = css`
    height: fit-content;

    &:nth-of-type(1) {
      grid-column: 1;
      grid-row: 2/4;
      margin: ${(111 / 189) * -100}% 0;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1;
        grid-row: 1;
        margin: ${(198 / 330) * 100}% 0 0 0;
      }
    }

    &:nth-of-type(2) {
      grid-column: 2;
      grid-row: 1/3;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2;
        grid-row: 1;
        margin: ${(0 / 330) * 100}% 0 0 0;
      }
    }

    &:nth-of-type(3) {
      grid-column: 2;
      grid-row: 3/5;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3;
        grid-row: 1;
        margin: ${(223 / 330) * 100}% 0 0 0;
      }
    }

    &:nth-of-type(4) {
      grid-column: 1;
      grid-row: 4/6;
      margin: ${(111 / 189) * -100}% 0;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 4;
        grid-row: 1;
        margin: ${(100 / 330) * 100}% 0 0 0;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
  `

  css.backgroundText = css`
    display: none;

    @media (min-width: ${theme.breakpoints.tablet}) {
      position: absolute;
      top: 40%;
      right: 0;
      left: 0;
      display: flex;
    }
  `

  return (
    <MotionBackground
      trigger={ref}
      color={
        backgroundColor
          ? theme.colors[themeCurrent][backgroundColor]
          : theme.colors[themeCurrent].three
      }
      disableLeave={disableLeave}
    >
      <section ref={ref} css={css.section}>
        <GraphicBackgroundText
          backgroundText={backgroundText}
          trigger={ref}
          css={css.backgroundText}
        />
        <div css={css.container}>
          <div css={css.grid}>
            <div css={css.gridItem}>
              <div
                css={css`
                  position: relative;
                `}
              >
                <MotionFade>
                  <div css={css.subheading}>{subheading}</div>
                  <h1 css={css.heading}>{heading}</h1>
                </MotionFade>
              </div>
            </div>
            <div css={css.gridItem}>
              <MotionFade delay={theme.motion.stagger.each}>
                <div
                  css={css.text}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </MotionFade>
            </div>
          </div>
        </div>
        <div css={css.containerAlt}>
          <div css={css.imageGrid}>
            <MotionParallax
              trigger={ref}
              matchMedia={`(min-width: ${theme.breakpoints.tablet})`}
            >
              {items.map((item, index) => (
                <div
                  ref={refs.current[index]}
                  key={index}
                  css={css.imageGridItem}
                >
                  <WrapperDevice inverse={inverse}>
                    <BaseVideo src={item.video.src} image={item.image.src} />
                  </WrapperDevice>
                </div>
              ))}
            </MotionParallax>
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}

CaseStudyIdYHaQNzfkUh.defaultProps = {
  disableLeave: false,
}

export const query = graphql`
  fragment CaseStudyIdYHaQNzfkUh on DataJsonComponentsProps {
    CaseStudyIdYHaQNzfkUh {
      inverse
      disableLeave
      backgroundColor
      subheading
      heading
      text
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
      backgroundText
    }
  }
`
