import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseVideo from "../components/BaseVideo"
import MotionFade from "../components/MotionFade"
import MotionBackground from "../components/MotionBackground"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdVDk177Z7Zf({
  inverse,
  subheading,
  heading,
  text,
  items,
  backgroundText,
}) {
  const theme = useTheme()
  const ref = useRef(null)
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    overflow: hidden;
    color: ${inverse ? theme.colors.global.light : theme.colors.global.dark};

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    position: relative;
    max-width: none;
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedPartial}

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(odd) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/12;
      }
    }

    &:nth-of-type(even) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 4/15;
      }
    }

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2/8;
      }
    }
  `

  css.image = css`
    vertical-align: middle;
  `

  css.backgroundText = css`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  `

  return (
    <MotionBackground trigger={ref} color={theme.colors[themeCurrent].one}>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          <GraphicBackgroundText
            backgroundText={backgroundText}
            trigger={ref}
            css={css.backgroundText}
          />
          <div css={css.grid}>
            <div css={css.gridItem}>
              <MotionFade>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <div css={css.subheading}>{subheading}</div>
                  <h1 css={css.heading}>{heading}</h1>
                </div>
                <div css={css.text}>{text}</div>
              </MotionFade>
            </div>
            {items.map((item, index) => (
              <div key={index} css={css.gridItem}>
                <BaseVideo
                  src={item.video.src}
                  image={item.image.src}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}

export const query = graphql`
  fragment CaseStudyIdVDk177Z7Zf on DataJsonComponentsProps {
    CaseStudyIdVDk177Z7Zf {
      inverse
      subheading
      heading
      text
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
      backgroundText
    }
  }
`
