import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { Link } from "gatsby"
import { gsap, ScrollTrigger } from "gsap/all"

import MotionBackground from "../components/MotionBackground"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function FrontPageAbout({ heading, text, link }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 150px 0;
  `

  css.container = css`
    ${theme.mixins.container.xl}
    width: 100%;
    max-width: 1520px;
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: ${theme.grid.gutter};
    grid-row-gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-row-gap: 90px;
    }
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 10;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8 / 12;
        grid-row: 2;
      }
    }
  `

  css.heading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "50px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    margin: 0;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.text = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "16px",
        toSize: "16px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "lineHeight",
        fromSize: "26px",
        toSize: "26px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.link = css`
    position: relative;
    display: inline-block;
    margin-top: 50px;
    padding-bottom: 6px;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "14px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.semiBold};
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.colors.typeAndTixel.one};

    &:hover {
      &:before {
        animation-name: wipe;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: block;
      height: 3px;
      background-color: ${theme.colors.typeAndTixel.one};
      transform-origin: left;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      animation-duration: 0.5s;
      transform-origin: right;
    }

    @keyframes wipe {
    	50% { transform-origin: right; transform: scaleX(0);}
    	50.001% { transform-origin: left; }
    	100% { transform-origin: left; transform: scaleX(1); }
  	}
  `

  return (
    <MotionBackground trigger={ref} invalidate={false} color={theme.colors.global.light}>
      <FrontPageAboutMotion trigger={ref}>
        <section ref={ref} css={css.section} className="panel">
          <div css={css.container}>
            <div css={css.grid}>
              <div css={css.gridItem}>
                <h1 css={css.heading} className="heading">
                  {heading}
                </h1>
              </div>
              <div css={css.gridItem}>
                <div className="content">
                  <div css={css.text}>{text}</div>
                  <Link css={css.link} to={link.href}>
                    {link.text}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FrontPageAboutMotion>
    </MotionBackground>
  )
}

function FrontPageAboutMotion({ trigger, children }) {
  const theme = useTheme()

  useEffect(() => {
    const element = trigger.current
    const heading = element.querySelectorAll(".heading")
    const content = element.querySelectorAll(".content")
    const linkUnderline = element.querySelectorAll(".linkUnderline")
    const timeline = gsap.timeline({
      paused: true,
    })

    timeline
      .from(
        heading,
        {
          y: "-10vh",
          autoAlpha: 0,
          duration: 1,
          ease: "none",
        },
        0
      )
      .from(
        content,
        {
          autoAlpha: 0,
          duration: 1,
          ease: "none",
        },
        0
      )
      .from(
        linkUnderline,
        {
          scaleX: 0,
          transformOrigin: "left",
          duration: 1,
          ease: theme.motion.ease,
        },
        0.5
      )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      start: theme.motion.scrollTrigger.start,
      end: '+=70%',
      scrub: true,
      toggleActions: "play none none reverse",
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [theme, trigger])

  return <>{children}</>
}

export const query = graphql`
  fragment FrontPageAbout on DataJsonComponentsProps {
    FrontPageAbout {
      heading
      text
      link {
        href
        text
      }
    }
  }
`
