import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import { useMousePosition } from "../utilities/hooks"
import { percentage, randomInteger, fluid } from "../utilities/functions"
import BaseImage from "../components/BaseImage"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function PageAboutGallery({ backgroundText, items }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    position: relative;
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.items = css`
    max-width: ${theme.breakpoints.desktop};
    ${fluidRange(
      {
        prop: "height",
        fromSize: "610px",
        toSize: "1420px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    margin: auto;
  `

  css.backgroundText = css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    font-weight: ${theme.fontWeight.bold};
    color: #F3F3F3;
    -webkit-text-stroke: transparent;
  `

  return (
    <section ref={ref} css={css.section}>
      <GraphicBackgroundText
        backgroundText={backgroundText}
        trigger={ref}
        css={css.backgroundText}
      />
      <div css={css.items}>
        {items.map((item, index) => (
          <FrontPageCaseStudiesItemImage key={index} {...item} />
        ))}
      </div>
    </section>
  )
}

function FrontPageCaseStudiesItemImage(props) {
  const { image, min, max, ...other } = props
  const ref = useRef()
  const refImage = useRef()
  const theme = useTheme()
  const canvasWidth = 1920
  const canvasHeight = 1080
  const mouse = useMousePosition()
  const distance = useRef(randomInteger(25, 100))
  const speed = useRef(randomInteger(0, 200))

  useEffect(() => {
    const element = ref.current
    const elementSpeed = speed.current
    const timeline = gsap.timeline({ paused: true })

    timeline.fromTo(
      element,
      {
        yPercent: elementSpeed / 2,
      },
      {
        yPercent: elementSpeed / -2,
        duration: 1,
      },
      0
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      scrub: true,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [])

  useEffect(() => {
    const element = refImage.current
    const elementDistance = distance.current
    let mouseX = mouse.x
    let mouseY = mouse.y
    const viewportCenterX = window.innerWidth / 2
    const viewportCenterY = window.innerHeight / 2

    window.addEventListener("mousemove", e => {
      mouseX = e.x
      mouseY = e.y
    })

    gsap.ticker.add(() => {
      const percentageFromViewportCenterX = mouseX / viewportCenterX
      const percentageFromViewportCenterY = mouseY / viewportCenterY

      gsap.to(element, {
        x: percentageFromViewportCenterX * -elementDistance,
        y: percentageFromViewportCenterY * -elementDistance,
        duration: 1,
        overwrite: true,
      })
    })
  }, [])

  css.figure = css`
    position: absolute;
    margin: 0;

    ${fluidRange(
      {
        prop: "top",
        fromSize: `${min.top}px`,
        toSize: `${max.top}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "left",
        fromSize: `${min.left}px`,
        toSize: `${max.left}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "width",
        fromSize: `${min.width}px`,
        toSize: `${max.width}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "height",
        fromSize: `${min.height}px`,
        toSize: `${max.height}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.image = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${"" /* background-color: ${background}; */}
  `

  return (
    <figure ref={ref} css={css.figure} {...other}>
      <div ref={refImage} css={css.image}>
        <BaseImage css={css.image} src={image.src} />
      </div>
    </figure>
  )
}

export const query = graphql`
  fragment PageAboutGallery on DataJsonComponentsProps {
    PageAboutGallery {
      backgroundText
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        min {
          top
          left
          width
          height
        }
        max {
          top
          left
          width
          height
        }
      }
    }
  }
`
