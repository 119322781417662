import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"

import WrapperSticky from "../components/WrapperSticky"

export default function PageAboutAbout({ subheading, heading, items }) {
  const theme = useTheme()
  const ref = useRef(null)
  const refSticky = useRef(null)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    grid-row-gap: 80px;
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
      @media (min-width: ${theme.breakpoints.laptopSmall}) {
        grid-column: 3 / 7;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
      @media (min-width: ${theme.breakpoints.laptopSmall}) {
        grid-column: 9 / 14;
      }
    }
  `

  css.subheading = css`
    margin-top: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "24px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.item = css`
    &:not(:first-of-type) {
      ${fluidRange(
        {
          prop: "marginTop",
          fromSize: "80px",
          toSize: "100px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    }
  `

  css.itemHeading = css`
    margin: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "24px",
        toSize: "35px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.itemText = css`
    ${theme.mixins.typography.text1}
    margin-top: 30px;
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div ref={refSticky} css={css.gridItem}>
            <WrapperSticky trigger={refSticky}>
              <h2 css={css.subheading}>{subheading}</h2>
              <div css={css.heading}>{heading}</div>
            </WrapperSticky>
          </div>
          <div css={css.gridItem}>
            {items.map((item, index) => (
              <div key={index} css={css.item}>
                <h3 css={css.itemHeading}>{item.heading}</h3>
                <div css={css.itemText}>{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageAboutAbout on DataJsonComponentsProps {
    PageAboutAbout {
      subheading
      heading
      items {
        heading
        text
      }
    }
  }
`
