import React from "react"
import CaseStudyHeader from "./CaseStudyHeader"
import CaseStudyIntro from "./CaseStudyIntro"
import CaseStudyIdHpGfOzWKwu from "./CaseStudyIdHpGfOzWKwu"
import CaseStudyIdBdRZxkrFOa from "./CaseStudyIdBdRZxkrFOa"
import CaseStudyIdR5WjCRCjyT from "./CaseStudyIdR5WjCRCjyT"
import CaseStudyIdYHaQNzfkUh from "./CaseStudyIdYHaQNzfkUh"
import CaseStudyTestimonial from "./CaseStudyTestimonial"
import CaseStudyIdRFePoPyqH5 from "./CaseStudyIdRFePoPyqH5"
import CaseStudyIdMlfODxn9o4 from "./CaseStudyIdMlfODxn9o4"
import CaseStudyIdWMnaq1hG7p from "./CaseStudyIdWMnaq1hG7p"
import CaseStudyIdY0UgRNPtAG from "./CaseStudyIdY0UgRNPtAG"
import CaseStudyIdXqbfAIUIEd from "./CaseStudyIdXqbfAIUIEd"
import CaseStudyIdZ95Eg3vl01 from "./CaseStudyIdZ95Eg3vl01"
import CaseStudyIdNyaKiByc3O from "./CaseStudyIdNyaKiByc3O"
import CaseStudyIdB1TfiIezO9 from "./CaseStudyIdB1TfiIezO9"
import CaseStudyIdPGDJhFYDqp from "./CaseStudyIdPGDJhFYDqp"
import CaseStudyIdQWW1PLVjrI from "./CaseStudyIdQWW1PLVjrI"
import CaseStudyIdQWZ33jdLPs from "./CaseStudyIdQWZ33jdLPs"
import CaseStudyIdQ6bhEKaoDY from "./CaseStudyIdQ6bhEKaoDY"
import CaseStudyIdEwZEqVPkOg from "./CaseStudyIdEwZEqVPkOg"
import CaseStudyIdApMr5MM5MR from "./CaseStudyIdApMr5MM5MR"
import CaseStudyIdVDk177Z7Zf from "./CaseStudyIdVDk177Z7Zf"
import CaseStudyIdXx02yE9V1e from "./CaseStudyIdXx02yE9V1e"
import CaseStudyIdXSulUr4o8t from "./CaseStudyIdXSulUr4o8t"
import CaseStudyIdBFLOubsKQl from "./CaseStudyIdBFLOubsKQl"
import CaseStudyIdNhfue2625s from "./CaseStudyIdNhfue2625s"
import CaseStudyNext from "./CaseStudyNext"
import CaseStudyBackground from "./CaseStudyBackground"
import FrontPageHero from "./FrontPageHero"
import FrontPageCaseStudies from "./FrontPageCaseStudies"
import FrontPageAbout from "./FrontPageAbout"
import FrontPageForm from "./FrontPageForm"
import FrontPageFooter from "./FrontPageFooter"
import ArchiveCaseStudy from "./ArchiveCaseStudy"
import PageContactForm from "./PageContactForm"
import PageAboutHeader from "./PageAboutHeader"
import PageAboutAbout from "./PageAboutAbout"
import PageAboutServices from "./PageAboutServices"
import PageAboutGallery from "./PageAboutGallery"
import PageAboutClients from "./PageAboutClients"
import PageAboutCulture from "./PageAboutCulture"
import PageAboutAwards from "./PageAboutAwards"

const componentList = {
  CaseStudyHeader: CaseStudyHeader,
  CaseStudyIntro: CaseStudyIntro,
  CaseStudyIdHpGfOzWKwu: CaseStudyIdHpGfOzWKwu,
  CaseStudyIdBdRZxkrFOa: CaseStudyIdBdRZxkrFOa,
  CaseStudyIdR5WjCRCjyT: CaseStudyIdR5WjCRCjyT,
  CaseStudyIdYHaQNzfkUh: CaseStudyIdYHaQNzfkUh,
  CaseStudyTestimonial: CaseStudyTestimonial,
  CaseStudyIdRFePoPyqH5: CaseStudyIdRFePoPyqH5,
  CaseStudyIdMlfODxn9o4: CaseStudyIdMlfODxn9o4,
  CaseStudyIdWMnaq1hG7p: CaseStudyIdWMnaq1hG7p,
  CaseStudyIdY0UgRNPtAG: CaseStudyIdY0UgRNPtAG,
  CaseStudyIdXqbfAIUIEd: CaseStudyIdXqbfAIUIEd,
  CaseStudyIdZ95Eg3vl01: CaseStudyIdZ95Eg3vl01,
  CaseStudyIdNyaKiByc3O: CaseStudyIdNyaKiByc3O,
  CaseStudyIdB1TfiIezO9: CaseStudyIdB1TfiIezO9,
  CaseStudyIdPGDJhFYDqp: CaseStudyIdPGDJhFYDqp,
  CaseStudyIdQWW1PLVjrI: CaseStudyIdQWW1PLVjrI,
  CaseStudyIdQWZ33jdLPs: CaseStudyIdQWZ33jdLPs,
  CaseStudyIdQ6bhEKaoDY: CaseStudyIdQ6bhEKaoDY,
  CaseStudyIdEwZEqVPkOg: CaseStudyIdEwZEqVPkOg,
  CaseStudyIdApMr5MM5MR: CaseStudyIdApMr5MM5MR,
  CaseStudyIdVDk177Z7Zf: CaseStudyIdVDk177Z7Zf,
  CaseStudyIdXx02yE9V1e: CaseStudyIdXx02yE9V1e,
  CaseStudyIdXSulUr4o8t: CaseStudyIdXSulUr4o8t,
  CaseStudyIdBFLOubsKQl: CaseStudyIdBFLOubsKQl,
  CaseStudyIdNhfue2625s: CaseStudyIdNhfue2625s,
  CaseStudyNext: CaseStudyNext,
  CaseStudyBackground: CaseStudyBackground,
  FrontPageHero: FrontPageHero,
  FrontPageCaseStudies: FrontPageCaseStudies,
  FrontPageAbout: FrontPageAbout,
  FrontPageForm: FrontPageForm,
  FrontPageFooter: FrontPageFooter,
  ArchiveCaseStudy: ArchiveCaseStudy,
  PageContactForm: PageContactForm,
  PageAboutHeader: PageAboutHeader,
  PageAboutAbout: PageAboutAbout,
  PageAboutServices: PageAboutServices,
  PageAboutGallery: PageAboutGallery,
  PageAboutClients: PageAboutClients,
  PageAboutCulture: PageAboutCulture,
  PageAboutAwards: PageAboutAwards,
}

const LoopComponents = ({ components }) => {
  return (
    <>
      {components && (
        <>
          {components.map((component, index) => {
            const Component = componentList[component.name]
            return (
              <Component key={index} {...component.props[component.name]} />
            )
          })}
        </>
      )}
    </>
  )
}

export default LoopComponents
