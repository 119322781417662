import React from "react"
import { graphql } from "gatsby"
import { useDispatch } from "react-redux"
import { gsap, ScrollTrigger } from "gsap/all"

import TheSeo from "../components/TheSeo"
import LoopComponents from "../components/LoopComponents"
import { setThemeCurrent } from "../store/theme"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const Page = ({ data }) => {
  const page = data.dataJson
  const dispatch = useDispatch()

  if (page.theme) {
    dispatch(setThemeCurrent(page.theme))
  }

  // useEffect(() => {
  //   ScrollTrigger.getAll().forEach(scrollTrigger => scrollTrigger.refresh())
  //
  //   return () => {
  //     ScrollTrigger.getAll().forEach(scrollTrigger => scrollTrigger.refresh())
  //   }
  // })

  return (
    <div>
      <TheSeo title={page.meta.title} />
      <LoopComponents components={page.components} />
    </div>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      meta {
        title
      }
      title
      theme
      components {
        name
        props {
          ...CaseStudyHeader
          ...CaseStudyIntro
          ...CaseStudyIdHpGfOzWKwu
          ...CaseStudyIdBdRZxkrFOa
          ...CaseStudyIdR5WjCRCjyT
          ...CaseStudyIdYHaQNzfkUh
          ...CaseStudyTestimonial
          ...CaseStudyIdRFePoPyqH5
          ...CaseStudyIdMlfODxn9o4
          ...CaseStudyIdWMnaq1hG7p
          ...CaseStudyIdY0UgRNPtAG
          ...CaseStudyIdXqbfAIUIEd
          ...CaseStudyIdZ95Eg3vl01
          ...CaseStudyIdNyaKiByc3O
          ...CaseStudyIdNhfue2625s
          ...CaseStudyIdB1TfiIezO9
          ...CaseStudyIdPGDJhFYDqp
          ...CaseStudyIdQWW1PLVjrI
          ...CaseStudyIdQWZ33jdLPs
          ...CaseStudyIdQ6bhEKaoDY
          ...CaseStudyIdEwZEqVPkOg
          ...CaseStudyIdApMr5MM5MR
          ...CaseStudyIdVDk177Z7Zf
          ...CaseStudyIdXx02yE9V1e
          ...CaseStudyIdXSulUr4o8t
          ...CaseStudyIdBFLOubsKQl
          ...CaseStudyNext
          ...CaseStudyBackground
          ...FrontPageHero
          ...FrontPageAbout
          ...FrontPageForm
          ...PageContactForm
          ...PageAboutHeader
          ...PageAboutAbout
          ...PageAboutServices
          ...PageAboutGallery
          ...PageAboutClients
          ...PageAboutCulture
          ...PageAboutAwards
        }
      }
    }
  }
`
