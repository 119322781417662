import React, { useRef, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseImage from "../components/BaseImage"
import BaseVideo from "../components/BaseVideo"
import MotionParallax from "../components/MotionParallax"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdR5WjCRCjyT({ items, backgroundText, inverse }) {
  const ref = useRef(null)
  const refs = useRef(items.map(() => createRef(null)))
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    position: relative;

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "60px",
        toSize: "217px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${
      "" /* &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 74%;
      background-color: ${theme.colors[themeCurrent].one};
    } */
    }
  `

  css.container = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      ${theme.mixins.container.xl}
      padding: 0 30px;
      max-width: ${theme.breakpoints.desktop};
    }
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 0fr 1fr 0fr 1fr 0fr;
    grid-template-rows: 0 max-content max-content max-content 0;
    ${fluidRange(
      {
        prop: "gap",
        fromSize: "40px",
        toSize: "60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: none;
    }
  `

  css.gridItem = css`
    display: flex;
    justify-content: center;

    &:nth-of-type(1) {
      grid-column: 1/3;
      grid-row: 1/3;
      align-self: flex-end;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1;
        grid-row: 1;
        margin-top: ${(363 / 428) * 100}%;
      }
    }

    &:nth-of-type(2) {
      grid-column: 2/4;
      grid-row: 3/5;
      align-self: flex-start;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2;
        grid-row: 1;
      }
    }

    &:nth-of-type(3) {
      grid-column: 4/6;
      grid-row: 2/4;
      align-self: flex-end;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3;
        grid-row: 1;
        margin-top: ${(239 / 428) * 100}%;
      }
    }

    &:nth-of-type(4) {
      grid-column: 4/6;
      grid-row: 4/6;
      align-self: flex-start;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 4;
        grid-row: 1;
        margin-top: ${(115 / 428) * 100}%;
      }
    }
  `

  css.backgroundText = (offset) => css`
  position: absolute;
  right: 0;
  left: 0;
  top: ${offset};
  transform: translateY(-50%);
  -webkit-text-stroke-color: ${inverse ? theme.colors.global.light : theme.colors[themeCurrent].one};
`


  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
      {backgroundText && backgroundText.map((item, index) => (
            <GraphicBackgroundText
              key={index}
              backgroundText={item.text}
              trigger={ref}
              css={css.backgroundText(item.offset)}
            />
          ))}
        <div css={css.grid}>
          <MotionParallax trigger={ref}>
            {items.map((item, index) => {
              return (
                <div ref={refs.current[index]} key={index} css={css.gridItem}>
                  <CaseStudyIdR5WjCRCjyTItem {...item} />
                </div>
              )
            })}
          </MotionParallax>
        </div>
      </div>
    </section>
  )
}

function CaseStudyIdR5WjCRCjyTItem({ video, image }) {
  css.video = css`
    vertical-align: top;
    width: 100%;
    z-index: 1;

    > [style] {
      max-width: none !important;
    }
  `

  return (
    <>
      {image && !video && (
        <BaseImage css={css.video} src={image.src} />
      )}
      {video && image && (
        <BaseVideo css={css.video} src={video.src} image={image.src} />
      )}
    </>
  )
}

export const query = graphql`
  fragment CaseStudyIdR5WjCRCjyT on DataJsonComponentsProps {
    CaseStudyIdR5WjCRCjyT {
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
    }
  }
`
