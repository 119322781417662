import React, { useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import BaseImage from "../components/BaseImage"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function PageAboutAwards({ heading, items, toggle }) {
  const theme = useTheme()
  const ref = useRef(null)
  const [isToggled, setIsToggled] = useState(false)

  const handleClick = () => {
    setIsToggled(!isToggled)
    ScrollTrigger.refresh(true)
  }

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    grid-row-gap: 50px;
  `

  css.gridItem = css`
    grid-column: 1;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: 3 / 15;
    }
  `

  css.items = css`
    padding-bottom: 50px;

    ${toggle &&
    !isToggled &&
    css`
      position: relative;
      max-height: 268px;
      overflow-y: hidden;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 110px;
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    `}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "24px",
        toSize: "35px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.item = css`
    ${theme.mixins.grid.default}

    &:not(:first-of-type) {
      margin-top: 50px;
    }
  `

  css.itemData = css``

  css.itemDataOrganisation = css`
    font-weight: ${theme.fontWeight.semiBold};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 3 / span 3;
      font-weight: ${theme.fontWeight.normal};
    }
  `

  css.itemDataAward = css`
    font-weight: ${theme.fontWeight.semiBold};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 2 / span 2;
      font-weight: ${theme.fontWeight.normal};
    }
  `

  css.itemDataCategory = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 3 / span 3;
    }
  `

  css.itemDataProject = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 3 / span 3;
    }
  `

  css.itemDataYear = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 1 / span 1;
    }
  `

  css.toggle = css`
    ${theme.mixins.button}

    font-size: 18px;
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.typeAndTixel.one};
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <div css={css.heading}>{heading}</div>
          </div>
          <div css={css.gridItem}>
            <div css={css.items}>
              {items.map((item, index) => (
                <div key={index} css={css.item}>
                  <div css={[css.itemData, css.itemDataOrganisation]}>
                    {item.organisation}
                  </div>
                  <div css={[css.itemData, css.itemDataAward]}>
                    {item.award}
                  </div>
                  <div css={[css.itemData, css.itemDataCategory]}>
                    {item.category}
                  </div>
                  <div css={[css.itemData, css.itemDataProject]}>
                    {item.project}
                  </div>
                  <div css={[css.itemData, css.itemDataYear]}>{item.year}</div>
                </div>
              ))}
            </div>
            {toggle && (
              <button css={css.toggle} onClick={handleClick}>
                Load More +
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

PageAboutAwards.defaultProps = {
  toggle: false,
}

export const query = graphql`
  fragment PageAboutAwards on DataJsonComponentsProps {
    PageAboutAwards {
      heading
      items {
        organisation
        year
        award
        category
        project
      }
      toggle
    }
  }
`
