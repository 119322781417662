import React from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import BaseSvg from "../components/BaseSvg"
import MotionFade from "../components/MotionFade"

export default function CaseStudyTestimonial({ text, heading }) {
  const theme = useTheme()

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "310px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      text-align: center;
    }
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: ${theme.grid.gutter};
    }
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2/12;
      }
    }
  `

  css.heading = css`
    color: ${theme.colors.typeAndTixel.one};

    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "16px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "30px",
        toSize: "50px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )};
  `

  css.text = css`
    margin-top: 50px;
    font-weight: ${theme.fontWeight.semiBold};

    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "30px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "lineHeight",
        fromSize: "28px",
        toSize: "40px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.blockquote = css`
    position: relative;
    margin: 0;
  `

  css.icon = css`
    position: absolute;
    ${fluidRange(
      {
        prop: "width",
        fromSize: "146px",
        toSize: "220px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.iconLeft = css`
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  `

  css.iconRight = css`
    right: 0;
    bottom: 0;
    transform: translate(50%, 0%);
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <MotionFade>
              <blockquote css={css.blockquote}>
                <div css={[css.icon, css.iconLeft]}>
                  <BaseSvg src="quote-left" />
                </div>
                <div css={[css.icon, css.iconRight]}>
                  <BaseSvg src="quote-right" />
                </div>
                <div css={css.text}>{text}</div>
                <footer>
                  <div css={css.heading}>{heading}</div>
                </footer>
              </blockquote>
            </MotionFade>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyTestimonial on DataJsonComponentsProps {
    CaseStudyTestimonial {
      text
      heading
    }
  }
`
