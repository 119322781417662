import React from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import BaseSvg from "../components/BaseSvg"
import MotionFade from "../components/MotionFade"

export default function CaseStudyIntro({
  subheading,
  heading,
  client,
  items,
  text,
}) {
  const theme = useTheme()

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "310px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.subheading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    color: ${theme.colors.typeAndTixel.one}
  `

  css.heading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "54px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    margin: 0;
    font-weight: ${theme.fontWeight.semiBold};
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 50px;
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: ${theme.grid.gutter};
    }
  `

  css.gridItem = css`
    display: grid;
    gap: 50px;
    grid-column: span 2 / span 2;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: span 3 / span 3;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: span 6 / span 6;
        grid-column-start: ${6 + 1};
      }
    }
  `

  css.metaData = css`
    display: grid;
    gap: 30px;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
  `

  css.metaDataItem = css`
    &:nth-of-type(1) {
      display: none;

      @media (min-width: 768px) {
        display: flex;
        align-items: flex-start;
      }
    }
  `

  css.metaDataIcon = css`
    color: ${theme.colors.typeAndTixel.one};

    @media (min-width: 768px) {
      margin-top: 5px;
    }
  `

  css.metaDataHeading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "10px",
        toSize: "11px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.semiBold};
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${theme.colors.global.one};
  `

  css.metaDataContent = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "16px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.metaDataList = css`
    margin: 0;
    padding: 0;
    list-style: none;
  `

  css.metaDataListItem = css`
    margin-top: 20px;
  `

  css.text = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "22px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "lineHeight",
        fromSize: "24px",
        toSize: "37px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `
  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.subheading}>{subheading}</div>
        <h1 css={css.heading}>{heading}</h1>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <div css={css.metaData}>
              <div css={css.metaDataItem}>
                <div css={css.metaDataIcon}>
                  <BaseSvg src="plus" />
                </div>
              </div>
              <div css={css.metaDataItem}>
                <h2 css={css.metaDataHeading}>Client</h2>
                <div css={css.metaDataContent}>{client}</div>
              </div>
            </div>
            <div css={css.metaData}>
              <div css={css.metaDataItem}>
                <div css={css.metaDataIcon}>
                  <BaseSvg src="plus" />
                </div>
              </div>
              <div css={css.metaDataItem}>
                <h2 css={css.metaDataHeading}>What</h2>
                <div css={css.metaDataContent}>
                  <ul css={css.metaDataList}>
                    {items.map((item, index) => (
                      <li key={index} css={css.metaDataListItem}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div css={css.gridItem}>
            <MotionFade>
              <div css={css.text}>{text}</div>
            </MotionFade>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIntro on DataJsonComponentsProps {
    CaseStudyIntro {
      subheading
      heading
      client
      items
      text
    }
  }
`
