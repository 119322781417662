import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import GraphicBackgroundText from "../components/GraphicBackgroundText"
import BaseEmbed from "../components/BaseEmbed"
import MotionFade from "../components/MotionFade"
import MotionBackground from "../components/MotionBackground"

export default function CaseStudyIdBFLOubsKQl({
  subheading,
  heading,
  image,
  embed,
  text,
  backgroundText,
  inverse,
}) {
  const ref = useRef(null)
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    overflow: hidden;
    position: relative;
    color: ${inverse ? theme.colors.global.light : theme.colors.global.dark};
    ${'' /* background-color: ${theme.colors[themeCurrent].one}; */}

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    position: relative;
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    position: relative;
    max-width: none;

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 9/15;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3/15;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.media = css`
    position: relative;
    z-index: 1;
  `

  css.backgroundText = css`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  `

  return (
    <MotionBackground trigger={ref} color={theme.colors[themeCurrent].one}>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          <div css={css.grid}>
            <div css={css.gridItem}>
              <MotionFade>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <div css={css.subheading}>{subheading}</div>
                  <h1 css={css.heading}>{heading}</h1>
                </div>
                <div css={css.text}>{text}</div>
              </MotionFade>
            </div>
            <div css={css.gridItem}>
              <div css={css.media}>
                <BaseEmbed src={embed.src} />
              </div>
            </div>
          </div>
        </div>
        <GraphicBackgroundText
          backgroundText={backgroundText}
          trigger={ref}
          css={css.backgroundText}
        />
      </section>
    </MotionBackground>
  )
}

CaseStudyIdBFLOubsKQl.defaultProps = {
  inverse: true,
}

export const query = graphql`
  fragment CaseStudyIdBFLOubsKQl on DataJsonComponentsProps {
    CaseStudyIdBFLOubsKQl {
      inverse
      subheading
      heading
      text
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      embed {
        src
      }
      backgroundText
    }
  }
`
