import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseImage from "../components/BaseImage"
import BaseVideo from "../components/BaseVideo"
import MotionFade from "../components/MotionFade"
import MotionBackground from "../components/MotionBackground"
import GraphicBackgroundText from "../components/GraphicBackgroundText"
import WrapperSticky from "../components/WrapperSticky"

export default function CaseStudyIdZ95Eg3vl01({
  subheading,
  heading,
  text,
  items,
  backgroundText,
  backgroundColor,
}) {
  const theme = useTheme()
  const ref = useRef(null)
  const sticky = useRef(null)
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    overflow: hidden;
    color: ${theme.colors.global.light};

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "192px",
        toSize: "604px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    position: relative;
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/5;
        grid-row: 1 / ${items.length + 1};
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 6/13;
        grid-row: 1;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 6/13;
        grid-row: 2;
      }
    }

    &:nth-of-type(4) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 6/13;
        grid-row: 3;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.media = css`
    position: relative;
    z-index: 1;
  `

  css.backgroundText = css`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  `

  return (
    <MotionBackground trigger={ref} disableLeave={true} disableEnter={true} color={theme.colors[themeCurrent][backgroundColor]}>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          {backgroundText && (
            <GraphicBackgroundText
              backgroundText={backgroundText}
              trigger={ref}
              css={css.backgroundText}
            />
          )}
          <div css={css.grid}>
            <div css={css.gridItem} ref={sticky}>
              <MotionFade>
                <WrapperSticky trigger={sticky}>
                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <div css={css.subheading}>{subheading}</div>
                    <h1 css={css.heading}>{heading}</h1>
                  </div>
                  <div css={css.text}>{text}</div>
                </WrapperSticky>
              </MotionFade>
            </div>
            {items.map((item, index) => (
              <div key={index} css={css.gridItem}>
                <div css={css.media}>
                  {item.image && !item.video && (
                    <BaseImage src={item.image.src} />
                  )}
                  {item.video && item.image && (
                    <BaseVideo src={item.video.src} image={item.image.src} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}


CaseStudyIdZ95Eg3vl01.defaultProps = {
  backgroundColor: 'one',
}

export const query = graphql`
  fragment CaseStudyIdZ95Eg3vl01 on DataJsonComponentsProps {
    CaseStudyIdZ95Eg3vl01 {
      subheading
      heading
      text
      backgroundColor
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
      backgroundText
    }
  }
`
