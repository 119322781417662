import React from "react"
import { css } from "@emotion/react"

const BaseImageOriginal = props => {
  const { src, ...other } = props
  const width = src ? src.childImageSharp.original.width : null
  const height = src ? src.childImageSharp.original.height : null

  css.container = css`
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: ${(height / width) * 100}%;
    }
  `

  css.image = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `

  return (
    <div css={css.container} {...other}>
      <img
        src={src.childImageSharp.original.src}
        width={width}
        height={height}
        css={css.image}
        alt=""
      />
    </div>
  )
}

export default BaseImageOriginal
