import React, { useRef, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import { percentage } from "../utilities/functions"
import BaseVideo from "../components/BaseVideo"
import WrapperDevice from "../components/WrapperDevice"
import MotionFade from "../components/MotionFade"

export default function CaseStudyIdXx02yE9V1e({
  subheading,
  heading,
  text,
  items,
}) {
  const ref = useRef(null)
  const refs = useRef(items.map(() => createRef(null)))
  const theme = useTheme()

  css.section = css`
    position: relative;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 50px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas:
        "head head"
        "nav  main"
        "nav  foot";
      grid-template-rows:
        ${percentage(540, 3004)}
        ${percentage(540, 3004)}
        ${percentage(301, 3004)}
        ${percentage(240, 3004)}
        ${percentage(301, 3004)}
        ${percentage(540, 3004)}
        ${percentage(540, 3004)};
      grid-row-gap: 0px;
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/7;
        grid-row: 1/2;
      }

      @media (min-width: ${theme.breakpoints.laptop}) {
        grid-column: 1/5;
        grid-row: 1/2;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8/13;
        grid-row: 1/3;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/6;
        grid-row: 2/5;
      }
    }

    &:nth-of-type(4) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8/13;
        grid-row: 4/7;
      }
    }

    &:nth-of-type(5) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/6;
        grid-row: 6/8;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <MotionFade>
              <div
                css={css`
                  position: relative;
                `}
              >
                <div css={css.subheading}>{subheading}</div>
                <h1 css={css.heading}>{heading}</h1>
              </div>
              <div css={css.text}>{text}</div>
            </MotionFade>
          </div>
          {items.map((item, index) => (
            <div ref={refs.current[index]} key={index} css={css.gridItem}>
              <WrapperDevice>
                <BaseVideo src={item.video.src} image={item.image.src} />
              </WrapperDevice>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdXx02yE9V1e on DataJsonComponentsProps {
    CaseStudyIdXx02yE9V1e {
      subheading
      heading
      text
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
    }
  }
`
