import React, { useState } from "react"
import { Field, ErrorMessage, useField } from "formik"
import { css, useTheme } from "@emotion/react"
import { rgba } from "polished"

export default function FormFieldInput(props) {
  const { children, name, label, ...other } = props
  const theme = useTheme()
  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = value => {
    setIsFocused(value)
  }
  const [field, meta] = useField(name)
  const { value, error, touched } = meta

  css.field = (value, isFocused, error, touched) => css`
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: ${theme.fontWeight.normal};
    color: ${rgba('#1C2631', 0.5)};

    ${(value || isFocused) &&
    css`
      color: ${theme.colors.typeAndTixel.one};
    `}

    ${(error && touched) &&
    css`
      color: ${theme.colors.global.error};
    `}
  `

  css.input = css`
    display: flex;
    width: 100%;
    /* height: 60px; */
    border: 0;
    border-radius: 0;
    padding: 25px 0;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    resize: none;
    color: inherit;
  `

  css.label = (value, isFocused, error, touched) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transform-origin: center left;
    transition-property: transform, color;
    transition-duration: 150ms;
    transition-timing-functions: cubic-bezier(0.4, 0, 0.2, 1);
    color: inherit;

    ${(value || isFocused) &&
    css`
      transform: scale(${12 / 16}) translateY(-75%);
    `}
  `

  css.error = css`
    position: absolute;
    top: 100%;
    font-size: 12px;
    font-weight: ${theme.fontWeight.normal};
  `

  css.underline = css`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: currentColor;
    transform-origin: center left;
    transition-property: transform;
    transition-duration: 5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  `

  css.underlineFocus = (value, isFocused) => css`
    transform: scaleX(0);

    ${(value || isFocused) &&
    css`
      transform: scaleX(1);
    `}
  `

  css.underlineError = (error, touched) => css`
    transform: scaleX(0);

    ${(error && touched) &&
    css`
      transform: scaleX(1);
    `}
  `

  return (
    <div css={css.field(value, isFocused, error, touched)}>
      <Field
        css={css.input}
        name={name}
        {...other}
        onFocus={() => {
          handleFocus(true)
        }}
        onBlur={() => {
          handleFocus(false)
        }}
      />
      {label && (
        <label css={css.label(value, isFocused, error, touched)} htmlFor={name}>
          {label}
        </label>
      )}
      <ErrorMessage css={css.error} component="div" name={name} />
      <div css={[css.underline]} />
      <div css={[css.underline, css.underlineFocus(value, isFocused)]} />
      <div css={[css.underline, css.underlineError(error, touched)]} />
    </div>
  )
}
