import { useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

import { randomInteger } from "../utilities/functions"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const MotionParallax = ({ children, trigger, min, max, matchMedia }) => {
  const theme = useTheme()

  useEffect(() => {
    let scrollTrigger = null
    const slotChildren = children.map(slotChild => {
      return slotChild.ref.current
    })

    ScrollTrigger.saveStyles(slotChildren)
    const mediaQueries = {}

    mediaQueries[matchMedia] = () => {
      const timeline = gsap.timeline({ paused: true })

      slotChildren.forEach((slotChild, index) => {
        const elementSpeed = randomInteger(min, max)

        timeline.fromTo(
          slotChild,
          {
            yPercent: elementSpeed / 2,
          },
          {
            yPercent: elementSpeed / -2,
            duration: 1,
          },
          0
        )
      })

      scrollTrigger = ScrollTrigger.create({
        animation: timeline,
        trigger: trigger ? trigger.current : null,
        scrub: true,
      })
    }

    ScrollTrigger.matchMedia(mediaQueries)

    return () => {
      scrollTrigger.kill()
    }
  }, [theme, trigger, children])
  return children
}

MotionParallax.defaultProps = {
  min: 0,
  max: 200,
  matchMedia: "all",
}

export default MotionParallax
