import React from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import BaseImage from "../components/BaseImage"
import BaseVideo from "../components/BaseVideo"
import MotionFade from "../components/MotionFade"

export default function CaseStudyIdHpGfOzWKwu({
  subheading,
  heading,
  text,
  image,
  video,
}) {
  const theme = useTheme()

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "310px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 50px;
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: ${theme.grid.gutter};
    }
  `

  css.gridItem = css`
    grid-column: span 2 / span 2;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: span 6 / span 6;
        grid-column-start: ${6 + 1};
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.media = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <MotionFade>
              <div
                css={css`
                  position: relative;
                `}
              >
                <div css={css.subheading}>{subheading}</div>
                <h1 css={css.heading}>{heading}</h1>
              </div>
              <div css={css.text}>{text}</div>
            </MotionFade>
          </div>
        </div>
        <div css={css.media}>
          {image && !video && <BaseImage src={image.src} />}
          {video && image && (
            <BaseVideo src={video.src} image={image.src} />
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdHpGfOzWKwu on DataJsonComponentsProps {
    CaseStudyIdHpGfOzWKwu {
      subheading
      heading
      text
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      video {
        src {
          publicURL
        }
      }
    }
  }
`
