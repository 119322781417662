import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"

import BaseImage from "../components/BaseImage"

export default function PageAboutCulture({ subheading, heading, items }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "80px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
    }
  `

  css.subheading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "24px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "40px",
        toSize: "78px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <h2 css={css.subheading}>{subheading}</h2>
            <div css={css.heading}>{heading}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageAboutCulture on DataJsonComponentsProps {
    PageAboutCulture {
      subheading
      heading
    }
  }
`
