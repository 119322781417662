import React from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, math } from "polished"

import BaseImage from "../components/BaseImage"

export default function CaseStudyIdMlfODxn9o4({ image }) {
  const theme = useTheme()

  css.section = css`
    overflow: hidden;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "310px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    margin: 0 ${math(`${theme.grid.gutter} * -1`)};
  `

  css.gridItem = css`
    grid-column: 1 / 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/17;
      }
    }
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <BaseImage src={image.src} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdMlfODxn9o4 on DataJsonComponentsProps {
    CaseStudyIdMlfODxn9o4 {
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
