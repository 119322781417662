import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { Link } from "gatsby"
import { gsap, ScrollTrigger } from "gsap/all"

import BaseSvg from "../components/BaseSvg"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function FrontPageForm({
  subheading,
  heading,
  link,
  siblingsTotal,
}) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    overflow: hidden;
    bottom: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    text-align: center;
    color: ${theme.colors.global.light};
    background-color: ${theme.colors.typeAndTixel.one};
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: ${theme.grid.gutter};
    grid-row-gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2 / 7;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8 / 12;
      }
    }
  `

  css.subheading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "18px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    margin: 0;
    font-weight: ${theme.fontWeight.normal};
  `

  css.heading = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "20px",
        toSize: "30px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "60px",
        toSize: "120px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.semiBold};
  `

  css.headingUnderline = css`
    display: block;
    width: 100%;
    height: 0.1em;
    margin-top: 6px;
    background-color: ${theme.colors.global.light};
  `

  css.link = css`
    display: block;
    text-decoration: none;
    color: inherit;
  `

  css.icon = css`
    display: inline-block;
    color: inherit;

    ${fluidRange(
      {
        prop: "width",
        fromSize: "60px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "20px",
        toSize: "30px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  return (
    <FrontPageFormMotion trigger={ref} siblingsTotal={siblingsTotal}>
      <section ref={ref} css={css.section} className="panel">
        <div css={css.content} className="content">
          <div css={css.container}>
            <Link css={css.link} to={link.href} className="cursor">
              <div
                css={css`
                  position: relative;
                `}
              >
                <h2 css={css.subheading}>{subheading}</h2>
                <div css={css.heading}>
                  {heading}
                  <div
                    css={css.headingUnderline}
                    className="headingUnderline"
                  />
                </div>

                <div css={css.icon} className="icon">
                  <BaseSvg src="arrow-right" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </FrontPageFormMotion>
  )
}

function FrontPageFormMotion({ trigger, siblingsTotal, children }) {
  const theme = useTheme()

  useEffect(() => {
    const element = trigger.current
    const content = element.querySelectorAll(".content")
    const headingUnderline = element.querySelectorAll(".headingUnderline")
    const icon = element.querySelectorAll(".icon")
    const timeline = gsap.timeline({
      paused: true,
    })
    const scrollTriggers = []

    timeline
      .from(
        content,
        {
          y: "-50vh",
          duration: 1,
          ease: "none",
        },
        0
      )
      .from(
        icon,
        {
          autoAlpha: 0,
          rotate: 45,
          duration: 0.5,
          ease: theme.motion.ease,
        },
        0.5
      )
      .from(
        headingUnderline,
        {
          scaleX: 0,
          transformOrigin: "left",
          duration: 0.5,
          ease: theme.motion.ease,
        },
        0.5
      )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      end: "bottom bottom",
      scrub: 0.05,
    })

    scrollTriggers.push(scrollTrigger)

    return () => {
      scrollTriggers.forEach(scrollTrigger => scrollTrigger.kill())
    }
  }, [theme, trigger])

  return <>{children}</>
}

export const query = graphql`
  fragment FrontPageForm on DataJsonComponentsProps {
    FrontPageForm {
      subheading
      heading
      link {
        href
      }
      siblingsTotal
    }
  }
`
