import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import { percentage } from "../utilities/functions"
import BaseImageOriginal from "../components/BaseImageOriginal"
import BaseAudio from "../components/BaseAudio"
import MotionFade from "../components/MotionFade"

export default function CaseStudyIdXSulUr4o8t({
  subheading,
  heading,
  text,
  image,
  items,
}) {
  const theme = useTheme()
  const ref = useRef(null)
  const sticky = useRef(null)
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    position: relative;
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedPartial}
    align-items: center;
    grid-template-columns: repeat(6, 1fr);

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "50px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    position: relative;
    z-index: 1;
    grid-column: 1 / 7;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2/8;
        grid-row: 1;
      }
    }

    &:nth-of-type(2) {
      grid-column: 1/5;
      grid-row: 2;
      ${fluidRange(
        {
          prop: "marginTop",
          fromSize: "0px",
          toSize: "50px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3/9;
      }
    }

    &:nth-of-type(3) {
      grid-column: 1/5;
      grid-row: 3;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/7;
        grid-row: 3;
      }
    }

    &:nth-of-type(4) {
      grid-column: 1/5;
      grid-row: 4;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3/9;
        grid-row: 4;
      }
    }

    &:nth-of-type(5) {
      z-index: 0;
      grid-column: 4/7;
      grid-row: 2 / 6;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 10/14;
        grid-row: 1 / 6;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.audio = css`
    width: 100%;
  `

  css.image = css`
    transform: scaleX(-1);

    &:before {
      padding-bottom: ${percentage(836, 388)};
    }
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem} ref={sticky}>
            <MotionFade>
              <div
                css={css`
                  position: relative;
                `}
              >
                <div css={css.subheading}>{subheading}</div>
                <h1 css={css.heading}>{heading}</h1>
              </div>
              <div css={css.text}>{text}</div>
            </MotionFade>
          </div>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <div css={css.media}>
                <BaseAudio src={item.audio.src} css={css.audio} />
              </div>
            </div>
          ))}
          <div css={css.gridItem} ref={sticky}>
            <BaseImageOriginal css={css.image} src={image.src} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdXSulUr4o8t on DataJsonComponentsProps {
    CaseStudyIdXSulUr4o8t {
      subheading
      heading
      text
      image {
        src {
          childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
      items {
        audio {
          src {
            publicURL
          }
        }
      }
    }
  }
`
