import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import { useMousePosition } from "../utilities/hooks"
import { percentage, randomInteger, fluid } from "../utilities/functions"
import BaseImage from "../components/BaseImage"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function PageAboutHeader({ heading, image }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "200px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
  `

  css.gridItem = css`
    grid-column: 1;
    min-width: 0;

    &:nth-of-type(1) {
      grid-row: 2/4;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 17;
      }
    }

    &:nth-of-type(2) {
      grid-row: 1/3;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2 / 11;
      }
    }
  `

  css.image = css`
    min-width: 801px;
    margin-left: 50px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: 3 / 17;
      margin-left: 0;
    }
  `

  css.heading = css`
    margin: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "70px",
        toSize: "170px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
    white-space: pre-wrap;
    color: #a7aa00;
    mix-blend-mode: difference;
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <PageAboutHeaderImage>
              <BaseImage css={css.image} src={image.src} />
            </PageAboutHeaderImage>
          </div>
          <div css={css.gridItem}>
            <h1 css={css.heading}>{heading}</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

function PageAboutHeaderImage(props) {
  const { children, ...other } = props
  const ref = useRef()
  const refImage = useRef()
  const mouse = useMousePosition()
  const distance = useRef(randomInteger(25, 100))
  const speed = useRef(randomInteger(0, 200))

  useEffect(() => {
    const element = ref.current
    const elementSpeed = speed.current
    const timeline = gsap.timeline({ paused: true })

    timeline.fromTo(
      element,
      {
        yPercent: elementSpeed / 2,
      },
      {
        yPercent: elementSpeed / -2,
        duration: 1,
      },
      0
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      scrub: true,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [])

  useEffect(() => {
    const element = refImage.current
    const elementDistance = distance.current
    let mouseX = mouse.x
    let mouseY = mouse.y
    const viewportCenterX = window.innerWidth / 2
    const viewportCenterY = window.innerHeight / 2

    window.addEventListener("mousemove", e => {
      mouseX = e.x
      mouseY = e.y
    })

    gsap.ticker.add(() => {
      const percentageFromViewportCenterX = mouseX / viewportCenterX
      const percentageFromViewportCenterY = mouseY / viewportCenterY

      gsap.to(element, {
        x: percentageFromViewportCenterX * -elementDistance,
        y: percentageFromViewportCenterY * -elementDistance,
        duration: 1,
        overwrite: true,
      })
    })
  }, [])

  return (
    <figure ref={ref} {...other}>
      <div ref={refImage}>
        {children}
      </div>
    </figure>
  )
}

export const query = graphql`
  fragment PageAboutHeader on DataJsonComponentsProps {
    PageAboutHeader {
      heading
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
