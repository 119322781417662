import { useEffect } from "react"
import { useTheme } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

import { timelineScrubEqualize } from "../utilities/functions"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const MotionBackground = ({
  children,
  trigger,
  color,
  disableEnter,
  disableLeave,
  invalidate,
  markers,
}) => {
  const theme = useTheme()

  useEffect(() => {
    const element = document.body
    const colorOriginal = theme.colors.global.light
    const colorTarget = color
    const scrollTriggers = []
    const timeline = gsap.timeline({ paused: true })

    if (!disableEnter) {
      const timelineEnter = gsap.timeline()

      timelineEnter.to(element, {
        backgroundColor: colorTarget,
        duration: () => {
          // return 1
          return timelineScrubEqualize(trigger.current).timelineDurationScrub
        },
        ease: "none",
      })

      timeline.add(timelineEnter)

      // console.log("timelineEnter", timelineEnter.duration(), timelineEnter)
    }

    const timelineSurplus = gsap.timeline()

    timelineSurplus.to(
      {},
      {
        duration: () => {
          return (
            timelineScrubEqualize(trigger.current).timelineDurationSurplus +
            (disableEnter
              ? timelineScrubEqualize(trigger.current).timelineDurationScrub
              : 0) +
            (disableLeave
              ? timelineScrubEqualize(trigger.current).timelineDurationScrub
              : 0)
          )
        },
        ease: "none",
      }
    )

    timeline.add(timelineSurplus)

    // console.log("timelineSurplus", timelineSurplus.duration(), timelineSurplus)

    if (!disableLeave) {
      const timelineLeave = gsap.timeline()

      timelineLeave.to(element, {
        backgroundColor: colorOriginal,
        duration: () => {
          return timelineScrubEqualize(trigger.current).timelineDurationScrub
        },
        ease: "none",
      })

      timeline.add(timelineLeave)

      // console.log("timelineLeave", timelineLeave.duration(), timelineLeave)
    }

    // console.log("timeline", timeline.duration(), timeline)

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: trigger ? trigger.current : null,
      start: `top center`,
      end: `bottom center`,
      scrub: true,
      onToggle: self => {
        if (invalidate) {
          self.refresh()
        }
      },
      invalidateOnRefresh: true,
      markers: markers,
    })

    scrollTriggers.push(scrollTrigger)

    return () => {
      scrollTriggers.forEach(scrollTrigger => scrollTrigger.kill())
      gsap.set(element, { backgroundColor: colorOriginal })
    }
  }, [theme, trigger, color, disableEnter, disableLeave])
  return children
}

MotionBackground.defaultProps = {
  invalidate: true,
  markers: false,
}

export default MotionBackground
