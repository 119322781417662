import React from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import MotionFade from "../components/MotionFade"

export default function CaseStudyIdWMnaq1hG7p({ items }) {
  const theme = useTheme()

  css.section = css`
    overflow: hidden;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/6;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 8/13;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <CaseStudyIdWMnaq1hG7pItem {...item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

function CaseStudyIdWMnaq1hG7pItem({ subheading, heading, text }) {
  return (
    <MotionFade>
      <div
        css={css`
          position: relative;
        `}
      >
        <div css={css.subheading}>{subheading}</div>
        <h1 css={css.heading}>{heading}</h1>
      </div>
      <div css={css.text}>{text}</div>
    </MotionFade>
  )
}

export const query = graphql`
  fragment CaseStudyIdWMnaq1hG7p on DataJsonComponentsProps {
    CaseStudyIdWMnaq1hG7p {
      items {
        subheading
        heading
        text
      }
    }
  }
`
