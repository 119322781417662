import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import BaseImage from "../components/BaseImage"

export default function CaseStudyIdXqbfAIUIEd({
  subheading,
  heading,
  text,
  items,
}) {
  const theme = useTheme()
  const ref = useRef(null)

  useEffect(() => {
    const element = ref.current
    const images = element.querySelectorAll(".image")
    const timeline = gsap.timeline({ paused: true })

    images.forEach((image, index) => {
      const isEven = index % 2 === 0
      const x = isEven ? 100 : -100
      const xPercent = isEven ? -100 : 100

      timeline.from(
        image,
        {
          "--x": x,
          "--xPercent": xPercent,
          duration: 1,
        },
        0
      )
    })

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: ref.current,
      scrub: true,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [theme])

  css.section = css`
    overflow: hidden;
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    max-width: ${theme.breakpoints.desktop};
    margin: auto;
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;

    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "20px",
        toSize: "75px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;
    display: flex;
    min-width: 0;

    &:nth-of-type(even) {
      justify-content: flex-end;
    }

    &:nth-of-type(odd) {
      justify-content: flex-start;
    }

    &:nth-of-type(1) {
      grid-row: 1;
    }

    &:nth-of-type(2) {
      grid-row: 2;
    }
  `

  css.media = css`
    --x: 0;
    --y: 0;
    --scale: 1;
    --xPercent: 0;
    --yPercent: 0;
    --rotation: 0deg; // Need 'deg' to make Edge happy
    --translate: translate(calc(var(--x) * 1vw), calc(var(--y) * 1vh));
    --translate-percent: translate(
      calc(var(--xPercent) * 1%),
      calc(var(--yPercent) * 1%)
    );
    transform: var(--translate-percent) var(--translate) scale(var(--scale))
      rotate(var(--rotation));
  `

  css.image = css`
    vertical-align: middle;
    ${fluidRange(
      {
        prop: "height",
        fromSize: "228px",
        toSize: "480px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    > div {
      &:first-of-type {
        height: 100% !important;
      }
    }

    & [role="presentation"] {
      height: 100% !important;
      width: auto !important;
      max-width: none !important;
    }
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <div className="image" css={css.media}>
                <BaseImage
                  css={css.image}
                  src={item.image.src}
                  sizes="(min-width: 1280px) 3940px, 100vw"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdXqbfAIUIEd on DataJsonComponentsProps {
    CaseStudyIdXqbfAIUIEd {
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
