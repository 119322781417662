import React from "react"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

const WrapperDevice = props => {
  const { children, color, inverse, ...other } = props
  const theme = useTheme()

  css.device = css`
    position: relative;
    z-index: 1;
    overflow: hidden;
    vertical-align: top;
    border: 1px solid
      ${!inverse ? theme.colors.global.light : theme.colors.global.two};
    box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.04);

    ${fluidRange(
      {
        prop: "borderWidth",
        fromSize: "10px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "borderRadius",
        fromSize: "20px",
        toSize: "50px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    > * {
      vertical-align: middle;
    }
  `

  return (
    <div css={css.device} {...other}>
      {children}
    </div>
  )
}

export default WrapperDevice
