import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseImageOriginal from "../components/BaseImageOriginal"
import MotionFade from "../components/MotionFade"
import MotionBackground from "../components/MotionBackground"

export default function CaseStudyIdY0UgRNPtAG({
  subheading,
  heading,
  image,
  text,
  backgroundColor,
}) {
  const ref = useRef(null)
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    overflow: hidden;
    position: relative;
    color: ${theme.colors.global.light};
    ${"" /* background-color: ${theme.colors[themeCurrent].one}; */}

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      grid-row: 1;
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/8;
      }
    }

    &:nth-of-type(2) {
      grid-row: 2;
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/13;
      }
    }

    &:nth-of-type(3) {
      grid-row: 3;
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1/8;
      }
    }
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  css.image = css`
    z-index: 1;
  `

  return (
    <MotionBackground trigger={ref} 
    color={
      backgroundColor
        ? theme.colors[themeCurrent][backgroundColor]
        : theme.colors[themeCurrent].one
    }>
      <section ref={ref} css={css.section}>
        <div css={css.container}>
          <div css={css.grid}>
            <div css={css.gridItem}>
              <MotionFade>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <div css={css.subheading}>{subheading}</div>
                  <h1 css={css.heading}>{heading}</h1>
                </div>
              </MotionFade>
            </div>
            <div css={css.gridItem}>
              <div>
                <BaseImageOriginal
                  src={image.src}
                  alt=""
                />
              </div>
            </div>
            <div css={css.gridItem}>
              <MotionFade>
                <div css={css.text}>{text}</div>
              </MotionFade>
            </div>
          </div>
        </div>
      </section>
    </MotionBackground>
  )
}

export const query = graphql`
  fragment CaseStudyIdY0UgRNPtAG on DataJsonComponentsProps {
    CaseStudyIdY0UgRNPtAG {
      backgroundColor
      subheading
      heading
      text
      image {
        src {
          childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`
