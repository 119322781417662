import React, { useRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"

import MotionFade from "../components/MotionFade"
import BaseImage from "../components/BaseImage"

export default function CaseStudyIdQWW1PLVjrI({ inverse, items }) {
  const ref = useRef(null)
  const theme = useTheme()

  css.section = css`
    overflow: hidden;
    color: ${inverse ? theme.colors.global.light : "inherit"};

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  `

  css.gridItem = css`
    grid-column: 1 / 1;
  `

  css.subheading = css`
    ${theme.mixins.typography.subheading1}
  `

  css.heading = css`
    ${theme.mixins.typography.heading1}
  `

  css.text = css`
    ${theme.mixins.typography.text1}
    margin-top: 50px;
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          {items.map((item, index) => {
            const gridItem = item.grid.map(media => {
              return css`
                @media (min-width: ${theme.breakpoints[media.media]}) {
                  grid-column: ${media.column};
                }
              `
            })

            return (
              <div key={index} css={[css.gridItem, gridItem]}>
                <MotionFade>
                  {item.heading && <div css={css`position: relative;`}>
                    {item.subheading && <div css={css.subheading}>{item.subheading}</div>}
                    {item.heading &&<h2 css={css.heading}>{item.heading}</h2>}
                  </div>}
                  {item.text && <div css={css.text}>{item.text}</div>}
                  {item.image && <BaseImage src={item.image.src} />}
                </MotionFade>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CaseStudyIdQWW1PLVjrI on DataJsonComponentsProps {
    CaseStudyIdQWW1PLVjrI {
      inverse
      items {
        grid {
          media
          column
        }
        subheading
        heading
        text
      }
    }
  }
`
