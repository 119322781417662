import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"

import BaseImage from "../components/BaseImage"

export default function PageAboutServices({ subheading, heading, items }) {
  const theme = useTheme()
  const ref = useRef(null)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    ${theme.mixins.grid.bleedFull}
    ${fluidRange(
      {
        prop: "gridRowGap",
        fromSize: "80px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 15;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 3 / 6;
        grid-row: 2;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 7 / 10;
        grid-row: 2;
      }
    }

    &:nth-of-type(4) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 11 / 14;
        grid-row: 2;
      }
    }
  `

  css.subheading = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "24px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
  `

  css.heading = css`
    ${theme.mixins.typography.heading1Alt}
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.item = css`
    &:not(:first-of-type) {
      ${fluidRange(
        {
          prop: "marginTop",
          fromSize: "80px",
          toSize: "100px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    }
  `

  css.itemHeading = css`
    margin: 0;
    font-size: 24px;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.itemList = css`
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
  `

  css.itemListItem = css`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 5px;
    margin-top: 10px;

    &:before {
      content: "-";
    }
  `

  return (
    <section ref={ref} css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <h2 css={css.subheading}>{subheading}</h2>
            <div css={css.heading}>{heading}</div>
          </div>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <h3 css={css.itemHeading}>{item.heading}</h3>
              <ul css={css.itemList}>
                {item.items.map((item, index) => (
                  <li key={index} css={css.itemListItem}>
                    {item.heading}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageAboutServices on DataJsonComponentsProps {
    PageAboutServices {
      subheading
      heading
      items {
        heading
        items {
          heading
        }
      }
    }
  }
`
