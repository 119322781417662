import React, { useRef, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import { percentage } from "../utilities/functions"
import BaseImage from "../components/BaseImage"
import BaseVideo from "../components/BaseVideo"
import MotionParallax from "../components/MotionParallax"
import MotionBackground from "../components/MotionBackground"
import WrapperDevice from "../components/WrapperDevice"
import WrapperAspectRatio from "../components/WrapperAspectRatio"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyIdNhfue2625s({
  inverse,
  backgroundColor,
  subheading,
  heading,
  text,
  items,
  disableLeave = false,
  backgroundText
}) {
  const ref = useRef(null)
  const refs = useRef(items.map(() => createRef(null)))
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}

    @media (min-width: ${theme.breakpoints.tablet}) {
      max-width: 1618px;
    }
  `

  const gridWidthMobile = 334
  const gridHeightMobile = 800
  const gridWidthDesktop = 1652
  const gridHeightDesktop = 2550

  css.aspectRatio = css`
    padding-bottom: ${percentage(gridHeightMobile, gridWidthMobile)};

    @media (min-width: ${theme.breakpoints.tablet}) {
      padding-bottom: ${percentage(gridHeightDesktop, gridWidthDesktop)};
    }
  `

  css.grid = css`
    display: grid;
    grid-template-columns:
      ${percentage(75, gridWidthMobile)}
      ${percentage(75, gridWidthMobile)}
      ${percentage(34, gridWidthMobile)}
      ${percentage(34, gridWidthMobile)}
      ${percentage(116, gridWidthMobile)};
    grid-template-rows:
      ${percentage(128, gridHeightMobile)}
      ${percentage(175, gridHeightMobile)}
      ${percentage(33, gridHeightMobile)}
      ${percentage(95, gridHeightMobile)}
      ${percentage(208, gridHeightMobile)}
      ${percentage(33, gridHeightMobile)}
      ${percentage(95, gridHeightMobile)}
      ${percentage(208, gridHeightMobile)};
    height: 100%;

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns:
        ${percentage(380, gridWidthDesktop)}
        ${percentage(122, gridWidthDesktop)}
        ${percentage(380, gridWidthDesktop)}
        ${percentage(168, gridWidthDesktop)}
        ${percentage(502, gridWidthDesktop)};
      grid-template-rows:
        ${percentage(230, gridHeightDesktop)}
        ${percentage(719, gridHeightDesktop)}
        ${percentage(135, gridHeightDesktop)}
        ${percentage(230, gridHeightDesktop)}
        ${percentage(719, gridHeightDesktop)}
        ${percentage(230, gridHeightDesktop)}
        ${percentage(719, gridHeightDesktop)}
        ${percentage(135, gridHeightDesktop)}
        ${percentage(230, gridHeightDesktop)}
        ${percentage(230, gridHeightDesktop)}
        ${percentage(719, gridHeightDesktop)};
    }
  `

  css.gridItem = css`
    position: relative;

    &:nth-of-type(1) {
      grid-column: 1 / 3;
      grid-row: 2 / 5;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 3;
        grid-row: 2 / 5;
      }
    }

    &:nth-of-type(2) {
      grid-column: 2 / 5;
      grid-row: 4 / 6;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 2 / 4;
        grid-row: 3 / 6;
      }
    }

    &:nth-of-type(3) {
      grid-column: 4 / 6;
      grid-row: 1 / 3;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 5 / 6;
        grid-row: 1 / 4;
      }
    }

    &:nth-of-type(4) {
      grid-column: 1 / 3;
      grid-row: 7 / 10;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 3;
        grid-row: 6 / 9;
      }
    }

    &:nth-of-type(5) {
      grid-column: 4 / 6;
      grid-row: 7 / 9;

      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 5 / 6;
        grid-row: 5 / 8;
      }
    }
  `

  css.backgroundText = (offset) => css`
  position: absolute;
  right: 0;
  left: 0;
  top: ${offset};
  transform: translateY(-50%);
  -webkit-text-stroke-color: ${inverse ? theme.colors.global.light : theme.colors[themeCurrent].one};
`

  return (
    <MotionBackground
      trigger={ref}
      color={
        backgroundColor
          ? theme.colors[themeCurrent][backgroundColor]
          : 'transparent'
      }
      disableEnter={true}
      disableLeave={disableLeave}
    >
      <section ref={ref} css={css.section}>
      <div css={css.container}>
        {backgroundText && backgroundText.map((item, index) => (
            <GraphicBackgroundText
              key={index}
              backgroundText={item.text}
              trigger={ref}
              css={css.backgroundText(item.offset)}
            />
          ))}
      </div>
        <div css={css.container}>
          <WrapperAspectRatio css={css.aspectRatio}>
            <div css={css.grid}>
              <MotionParallax trigger={ref}>
                {items.map((item, index) => (
                  <div ref={refs.current[index]} key={index} css={css.gridItem}>
                    <WrapperDevice inverse={inverse}>
                      {item.image && !item.video && (
                        <BaseImage src={item.image.src} css={css.image} />
                      )}
                      {item.video && item.image && (
                        <BaseVideo
                          src={item.video.src}
                          image={item.image.src}
                          css={css.image}
                        />
                      )}
                    </WrapperDevice>
                  </div>
                ))}
              </MotionParallax>
            </div>
          </WrapperAspectRatio>
        </div>
      </section>
    </MotionBackground>
  )
}

export const query = graphql`
  fragment CaseStudyIdNhfue2625s on DataJsonComponentsProps {
    CaseStudyIdNhfue2625s {
      inverse
      backgroundColor
      disableLeave
      backgroundText {
        offset
        text
      }
      items {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video {
          src {
            publicURL
          }
        }
      }
    }
  }
`
