import React, { useRef, useEffect } from "react"
import { css } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const AppBaseVideo = props => {
  const { src, image, ...other } = props
  const ref = useRef(null)
  const dataSrc = src.publicURL
  const poster = image
    ? image.childImageSharp.gatsbyImageData.images.fallback.src
    : null
  const width = image ? image.childImageSharp.gatsbyImageData.width : null
  const height = image ? image.childImageSharp.gatsbyImageData.height : null

  useEffect(() => {
    const loadSrc = () => {
      if (ref.current) {
        if (!ref.current.src) {
          ref.current.src = dataSrc
        }
        if (ref.current.paused) {
          ref.current.play()
        }
      }
    }

    const scrollTrigger = ScrollTrigger.create({
      trigger: ref.current,
      start: "-50% bottom",
      onEnter: loadSrc,
      onEnterBack: loadSrc,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [src, dataSrc])

  css.container = css`
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: ${(height / width) * 100}%;
    }
  `

  css.video = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `

  return (
    <div css={css.container} {...other}>
      <video
        ref={ref}
        muted
        playsInline
        data-src={dataSrc}
        poster={poster}
        width={width}
        height={height}
        css={css.video}
      />
    </div>
  )
}

export default AppBaseVideo
