import React, { useRef, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { useSelector } from "react-redux"

import BaseVideo from "../components/BaseVideo"
import WrapperDevice from "../components/WrapperDevice"
import MotionFade from "../components/MotionFade"
import MotionParallax from "../components/MotionParallax"
import MotionBackground from "../components/MotionBackground"
import GraphicBackgroundText from "../components/GraphicBackgroundText"

export default function CaseStudyBackground({
  inverse,
  backgroundColor,
  disableEnter,
  disableLeave,
}) {
  const ref = useRef(null)
  const theme = useTheme()
  const themeCurrent = useSelector(state => state.theme.current)

  css.section = css`
    position: absolute;
    transform: translateY(-50%);

    ${fluidRange(
      {
        prop: "height",
        fromSize: "100px",
        toSize: "300px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  return (
    <MotionBackground
      trigger={ref}
      color={
        backgroundColor
          ? theme.colors[themeCurrent][backgroundColor]
          : theme.colors[themeCurrent].one
      }
      disableEnter={disableEnter}
      disableLeave={disableLeave}
    >
      <section ref={ref} css={css.section}>
      </section>
    </MotionBackground>
  )
}

CaseStudyBackground.defaultProps = {
  backgroundColor: 'one',
  disableEnter: false,
  disableLeave: false,
}

export const query = graphql`
  fragment CaseStudyBackground on DataJsonComponentsProps {
    CaseStudyBackground {
      backgroundColor
      disableEnter
      disableLeave
    }
  }
`
